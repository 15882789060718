import { AWS } from './Login.js';
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useTranslation } from 'react-i18next';
import i18n from "./i18n.js"; //  archivo de configuración de lenguaje
import {
  DateRangePicker,
  TextInput,
  Grid, Badge, Card, Title, Subtitle, Text, Flex, Button, Icon, Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell, TableFoot, TableFooterCell
} from "@tremor/react";
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, SearchIcon, ShieldExclamationIcon, XIcon, QuestionMarkCircleIcon, UploadIcon, PlusIcon, SaveIcon } from "@heroicons/react/outline";
import { BedrockRuntimeClient, InvokeModelCommand } from '@aws-sdk/client-bedrock-runtime';

const bucketName = process.env.REACT_APP_BUCKET_NAME;


// Función para detectar texto con AWS Rekognition
const detectText = async (imageKey, bucketName) => {
  const rekognitionClient = new AWS.Rekognition();
  const params = {
    Image: {
      S3Object: {
        Bucket: bucketName,
        Name: imageKey
      }
    }
  };

  try {
    const response = await rekognitionClient.detectText(params).promise();

    // Check if response.TextDetections is an array and not empty before proceeding
    if (Array.isArray(response.TextDetections) && response.TextDetections.length > 0) {
      // Filter TextDetections to include only items with Type "LINE"
      const lineDetections = response.TextDetections.filter(item => item.Type === "LINE");
      // Map lineDetections to extract desired information
      const textDetails = lineDetections.map((detection) => ({
        DetectedText: detection.DetectedText
      }));
      return textDetails;
    } else {
      console.log("No text detections were found or no lines were detected.");
      return []; // Return an empty array or handle the absence of detections as needed
    }
  } catch (error) {
    console.error("Error al detectar texto:", error);
    throw error;
  }
};


// Función para verificar el tipo de documento con Rekognition custom labels
const detectLabels = async (imageKey, bucketName, projectVersionArn) => {
  const rekognitionCustom = new AWS.Rekognition();
  console.log('-------projectVersionArn: ', projectVersionArn);
  if (projectVersionArn) {
    const params = {
      Image: { S3Object: { Bucket: bucketName, Name: imageKey } },
      MaxResults: 1,
      MinConfidence: 80,
      ProjectVersionArn: projectVersionArn,
    };

    try {
      const responseLabels = await rekognitionCustom.detectCustomLabels(params).promise();
      console.log("Etiquetas detectadas:", responseLabels.CustomLabels);
      return responseLabels.CustomLabels.map(label => label.Name);
    } catch (error) {
      console.error("Error al detectar etiquetas custom y texto:", error);
      return [];
    }
  } else {
    console.error("Error al detectar etiquetas projectVersionArn null:", projectVersionArn);
    return [];
  }
};

async function formatearTexto(client, textDetails) {
  console.log('---->>>formatearTexto: ', textDetails);
  const textDetailsString = JSON.stringify(textDetails);

  // Construcción del prompt y envío del request a Bedrock
  const constructedModelPrompt = `Human: A continuación, se presentan los detalles extraídos de un documento de identificación. Los datos son los siguientes:\n\n${textDetailsString}\n\nOrganiza la información en formato JSON siguiendo el esquema: Apellidos, Nombres, Fecha de vencimiento. 

  - La fecha de vencimiento debe ser la más reciente entre todas las fechas presentes en los datos.
  - Usa camel case para las claves en el JSON y proporciona la información en español.
  
  Si algún dato no está disponible, omítelo en la respuesta en lugar de dejarlo en blanco.\n\nAssistant:`;

  const modelId = 'anthropic.claude-3-5-sonnet-20240620-v1:0';
  const payload = {
    "anthropic_version": "bedrock-2023-05-31",
    "max_tokens": 500,
    "messages": [
      {
        "role": "user",
        "content": constructedModelPrompt
      }
    ]
  };

  try {
    const data = await client.send(new InvokeModelCommand({
      body: JSON.stringify(payload),
      modelId,
      contentType: 'application/json',
      accept: 'application/json'
    }));

    const jsonString = new TextDecoder().decode(data.body);
    const response = JSON.parse(jsonString);

    const extractJson = (text) => {
      if (typeof text !== 'string') {
        console.error('Expected a string but got:', typeof text);
        return null;
      }

      // Usa una expresión regular para extraer el JSON entre las etiquetas de código
      const jsonMatch = text.match(/{\s*[^]*?\s*}/);
      return jsonMatch ? jsonMatch[0] : null;
    };

    // Obtén el texto del campo `content`
    const contentText = response.content?.[0]?.text;
    if (!contentText) {
      console.error('Content text is missing or undefined');
      return null;
    }

    // Extrae el JSON formateado
    const formattedJson = extractJson(contentText);

    if (formattedJson) {
      try {
        // Parsear el JSON
        const parsedJson = JSON.parse(formattedJson);
        console.log('Parsed JSON:', parsedJson);
        return parsedJson;
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
      }
    } else {
      console.error('No JSON found in the response');
      return null;
    }
  } catch (err) {
    console.error('Error:', err);
    return null;
  }
}
//====================================App=======================================

function App() {
  const { t } = useTranslation();
  const [locale, setLocale] = useState('es');  // idioma por defecto
  const [expirationDate, setExpirationDate] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [nombres, setNombres] = useState("");
  const [projectVersionArn, setProjectVersionArn] = useState(null);
  const s3 = new AWS.S3();

  const [detectedText, setDetectedText] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [labels, setLabels] = useState([]);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(''); // Estado para almacenar la URL de la imagen cargada


  const toggleLanguage = () => {
    // Cambia el idioma entre inglés y español
    const newLocale = locale === 'en' ? 'es' : 'en';
    setLocale(newLocale);

    // Cambia el idioma en i18next
    i18n.changeLanguage(newLocale);
  };

  // borrar  resultados del modal
  const clearResults = () => {
    setIsVisible(false); // Oculta el modal o el componente que muestra los resultados
    setLabels([]); // Limpia el arreglo de etiquetas detectadas
    setUploadedImageUrl(''); // Limpia la URL de la imagen cargada
    setExpirationDate("");
    setNombres("");
    setDetectedText([]);
    setLabels([]);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const data = await response.json();
        const projectVersion = data.projectVersion;
        setProjectVersionArn(projectVersion);
      } catch (error) {
        console.error('Error loading config:', error);
      }
    };

    fetchConfig();
  }, []);


  /////// Subir la imagen al s3
  function handleFileChange(event) {
    setLoadingFile(true);
    setExpirationDate("");
    setNombres("");
    setApellidos("");
    const file = event.target.files[0];
    if (!file) {
      console.error('No file selected');
      setLoadingFile(false);
      return;
    }
    // Initialize and configure BedrockRuntimeClient
    const client = new BedrockRuntimeClient({
      region: process.env.REACT_APP_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
      }
    });
    // Define imageKey aquí
    const imageKey = `IdImage-${Date.now()}.jpg`;

    // FileReader para leer el archivo seleccionado
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImageUrl(reader.result);
    };
    reader.readAsDataURL(file);

    const uploadParams = {
      Bucket: bucketName,
      Key: imageKey, // Usamos imageKey aquí
      Body: file,
      ContentType: file.type,
    };

    s3.upload(uploadParams, async function (err, data) {
      if (err) {
        console.error('Error uploading file:', err);
      } else {
        console.log('File uploaded correctly:', data.Location);
    
        const [textDetections, labelsResponse] = await Promise.all([
          detectText(imageKey, bucketName),
          detectLabels(imageKey, bucketName, projectVersionArn)
        ]);
    
        const datos = await formatearTexto(client, textDetections);
        setDetectedText(textDetections);
        setLabels(labelsResponse);
    
        // Verifica si los datos existen antes de acceder a ellos
        if (datos) {
          setExpirationDate(datos.fechaVencimiento || t('not_available'));  // Verifica que haya una fecha, de lo contrario muestra un valor por defecto
          setNombres(datos.nombres || t('not_available'));
          setApellidos(datos.apellidos || t('not_available'));
        } else {
          setExpirationDate(t('not_available'));
          setNombres(t('not_available'));
          setApellidos(t('not_available'));
        }
    
        setLoadingFile(false);
      }
    });
  }


  /// Visibilidad de elementos
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setExpirationDate("");
  };

  const validateResponse = [
    {
      "account": "johndoe",
      "address": "José Ignacio",
      "did_you_mean": "johndoe@hotmail.com",
      "disposable": "false",
      "domain": "hotmai.com",
      "free_email": "false",
      "processed_at": "2023-09-03 15:10:08",
      "request_id": "NTUzNzQ4OQ==-6ab9a329-849b-4342-8475-df7610ee4647",
      "request_status": "completed",
      "role_based": "Acosta Manani",
      "status": "invalid",
      "sub_status": "possible_typo",
      "toxic": "false"
    }
  ];

const tabledata = [
    {
      FirstName: "Rafael Jose",
      LastName: "Mendez Perez",
      Status: "valid",
      ID: "12345678",
      Date: "21/06/2024",
      Type: "🇵🇪 DNIe"
    },
    {
      FirstName: "Luisa María",
      LastName: "Rodríguez Mamani",
      Status: "expiring",
      ID: "12345678",
      Date: "11/04/2024",
      Type: "🇵🇪 DNI"
    },
    {
      FirstName: "Alejandra",
      LastName: "Paniagua Wong",
      Status: "valid",
      ID: "81163899",
      Date: "30/03/2027",
      Type: "🇪🇸 Passport"
    },
    {
      FirstName: "Jason Emmerson",
      LastName: "Lopez Vega",
      Status: "invalid",
      ID: "28928230",
      Date: "01/08/2023",
      Type: "🇵🇪 DNI"
    },
    {
      FirstName: "Miguel Angel",
      LastName: "Rodriguez Soto",
      Status: "valid",
      ID: "10307081",
      Date: "19/07/2025",
      Type: "🇪🇸 Passport"
    },
    {
      FirstName: "Manuela",
      LastName: "Mendez Perez",
      Status: "valid",
      ID: "67856003",
      Date: "07/06/2029",
      Type: "🇵🇪 DNIe"
    },
    {
      FirstName: "Diego",
      LastName: "Morales",
      Status: "valid",
      ID: "87738408",
      Date: "28/06/2025",
      Type: "🇵🇪 DNIe"
    },
    {
      FirstName: "Leonardo",
      LastName: "López",
      Status: "valid",
      ID: "30918606",
      Date: "27/02/2029",
      Type: "🇪🇸 Passport"
    },
    {
      FirstName: "Diego",
      LastName: "Jiménez",
      Status: "valid",
      ID: "46869408",
      Date: "06/11/2027",
      Type: "🇵🇪 DNIe"
    },
    {
      FirstName: "Gabriel",
      LastName: "Pérez",
      Status: "valid",
      ID: "30939824",
      Date: "29/07/2025",
      Type: "🇵🇪 DNI"
    },
    {
      FirstName: "Diego",
      LastName: "Herrera",
      Status: "expiring",
      ID: "47800800",
      Date: "01/02/2024",
      Type: "🇵🇪 DNIe"
    },
    {
      FirstName: "Luciana",
      LastName: "López",
      Status: "expiring",
      ID: "87839249",
      Date: "01/02/2024",
      Type: "🇵🇪 DNIe"
    }
];


  const statusAttributes = {
    'valid': { color: 'emerald', icon: CheckIcon },
    'expiring': { color: 'orange', icon: ShieldExclamationIcon },
    'invalid': { color: 'rose', icon: XIcon },
    'unknown': { color: 'gray', icon: QuestionMarkCircleIcon },
  };

  const getStatusAttribute = (status, attribute) => {
    return statusAttributes[status]?.[attribute] || statusAttributes['unknown'][attribute];
  };

  // Paginación

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);

  const nextPage = () => {
    if (currentPage < Math.ceil(tabledata.length / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tabledata.slice(indexOfFirstRecord, indexOfLastRecord);

  // fin Paginación 

  //resaltar popup con overlay oscuro
  const overlayStyles = {
    display: isVisible ? 'block' : 'none', // controla la visibilidad según la condición
    position: 'fixed', // posición fija
    top: '0',
    left: '0',
    width: '100vw', // ancho del viewport
    height: '100vh', // alto del viewport
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // color negro semitransparente
    zIndex: 1 // z-index bajo
  }



  return (
    <IntlProvider locale={locale}>
      <header className="bg-white shadow grid grid-cols-2 ">
        <div className="mx-10 max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
          <h1 className="text-1xl font-bold tracking-tight text-gray-900">{t('national_document_recognition')} v1</h1>
        </div>
      </header>
      <main className="pl-12 pr-12 mt-6">

        <Card className='mt-10' style={{ backgroundColor: '#f9f9f9' }} >

          <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >

            <Flex>
              <Card>
                <Subtitle>{t('you_can')}.</Subtitle>
                <Flex justifyContent='start' alignItems='center'> <Icon variant="light" icon={PlusIcon} size="sm" color="indigo" />
                  <Title className="ml-2"> {t('add_new_individual_identity')}</Title></Flex>
                <Flex justifyContent='evenly' alignItems='center' className="gap-6 mt-6" >
                  <TextInput icon={UploadIcon} placeholder={t('upload_new_file')} />
                  <Button color='emerald' icon={PlusIcon} onClick={toggleVisibility}>{t('add')}</Button>
                </Flex>
              </Card>
            </Flex> <Flex>
              <Card>
                <Subtitle>{t('you_can')}</Subtitle>
                <Flex justifyContent='start' alignItems='center'> <Icon variant="light" icon={SearchIcon} size="sm" color="indigo" /> <Title className="ml-2"> {t('search_identities')}</Title></Flex>
                <Flex justifyContent='evenly' alignItems='center' className="gap-10 mt-6" >
                  <TextInput icon={SearchIcon} placeholder={t('id_number')} />
                  <Button icon={SearchIcon} color="indigo">{t('search')}</Button>
                </Flex>
              </Card>
            </Flex>
          </Grid></Card>
        <Card style={{
          display: isVisible ? 'block' : 'none',
          position: 'fixed',
          top: '100px',
          left: '50%', // Centra el modal en la pantalla horizontalmente
          transform: 'translateX(-50%)', // Ajuste para centrar correctamente
          zIndex: "12",
          width: "80%",
          height: "70%"
        }}>
          <Flex justifyContent='between' alignItems='center'>
            <Title className="ml-2">{t('add_identity_document')}</Title>
            <Button icon={XIcon} size='sm' variant="light" onClick={clearResults}></Button>
          </Flex>
          <div className="flex mt-6">
            {/* Utiliza flexbox para dividir en columnas */}
            <div className="flex-1">
              {/* Columna izquierda para la carga del archivo y la imagen */}
              {
                uploadedImageUrl ? (
                  <img src={uploadedImageUrl} alt="Document Loaded" className="max-w-full h-auto" />
                ) : (
                  // Se muestra el input si no hay una imagen cargada aún
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg, image/png"
                    onChange={handleFileChange}
                    className="mb-4" // Espacio extra para separar del título si lo deseas
                  />
                )
              }
            </div>
            <div className="flex-1 ml-6">
              {loadingFile && 
              <img src="./spinner.gif" alt="loadingFile" style={{ width: '100%' }} />}
              {/* Columna derecha para los labels detectados */}
              {labels.length > 0 && (
                <div>
                  <h4 className='text-xl text-purple-900'>{t('national_document_detected')}</h4>
                  <ul className='text-xl font-bold text-purple-900'>
                    {labels.map((label, index) => (
                      <li key={index}>{label}</li>
                    ))}
                  </ul>
                  <hr className='my-4 border-t-2 border-gray-300' />
                </div>
              )}
              {detectedText.length > 0 && (
                <div className='text-xl'>
                  <h2>{t('expiration_date')}: {expirationDate}</h2>
                  <ul>
                    {t('last_names')}: {apellidos}
                  </ul>
                  <ul>
                  {t('names')}: {nombres}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Card>

        <Card className='mt-6'>
          <Flex >
            <Flex ><Title>{t('national_document_recognition_history')}</Title></Flex>
            <Flex justifyContent='end'><DateRangePicker className="max-w-sm" enableSelect={true} />
              <Button icon={SaveIcon} className='ml-4' size='sm' tooltip='Export' variant='secondary' iconPosition="left"></Button>
            </Flex></Flex>



          <div className="mt-6">
            <Card>

              <Table id="SingleValidationHistory" className="mt-5">
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>{t('name')}</TableHeaderCell>
                    <TableHeaderCell>{t('last_name')}</TableHeaderCell>
                    <TableHeaderCell>{t('status')}</TableHeaderCell>
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>{t('expiration_date')}</TableHeaderCell>
                    <TableHeaderCell>{t('type')}</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentRecords.map((item) => (
                    <TableRow key={item.Nombre}>
                      <TableCell>
                        <Flex justifyContent='start'><Text >{item.FirstName} </Text></Flex>
                      </TableCell>

                      <TableCell>
                        <Text>{item.LastName}</Text>
                      </TableCell>
                      <TableCell>
                        <Badge color={getStatusAttribute(item.Status, 'color')}>
                          <Text>{item.Status} </Text>
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <Text>{item.ID}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>{item.Date}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>{item.Type}</Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFoot>
                  <TableFooterCell colSpan={5}>
                    <Button size="xs" variant="secondary" onClick={prevPage} icon={ChevronLeftIcon}></Button>
                    <span className='mx-4' >{` Page ${currentPage} `}</span>
                    <Button size="xs" variant="secondary" onClick={nextPage} icon={ChevronRightIcon} ></Button>
                  </TableFooterCell>
                </TableFoot>

              </Table>

            </Card>

          </div>
        </Card>



      </main>
    </IntlProvider>
  );
}

export default App;