import React, { useState, useEffect, useRef, Fragment } from 'react';
import Webcam from 'react-webcam';
import { AWS } from './Login.js';
import { Dialog, Transition } from '@headlessui/react';
import { Disclosure } from '@headlessui/react';
import { FaChevronDown, FaUpload } from 'react-icons/fa';
import { Card, Button } from '@tremor/react';
import { RiMobileDownloadLine, RiUploadCloud2Line } from '@remixicon/react';
import { useTranslation } from 'react-i18next';

const bucketName = process.env.REACT_APP_BUCKET_NAME;
const sourceImageKey = `sourceImage-${Date.now()}.jpg`;
const targetImageKey = `targetImage-${Date.now()}.jpg`;


//=============================Main VideoSignature=========================

const VideoSignature = () => {
  const { t } = useTranslation(); 
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [detectedText, setDetectedText] = useState([]);
  const [FaceMatchDetail, setFaceMatchDetail] = useState(null);
  const [faceMatch, setFaceMatch] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingFace, setLoadingFace] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isPerson, setIsPerson] = useState(false);
  const [labels, setLabels] = useState([]);
  const [projectVersionArn, setProjectVersionArn] = useState(null);
  const [faceMatchExito, setFaceMatchExito] = useState(null);
  const [faceMatchError, setFaceMatchError] = useState(null);
  const [similarity, setSimilarity] = useState(null);

  const [sourceImageKey, setSourceImageKey] = useState(`sourceImage-${Date.now()}.jpg`);
  const [targetImageKey, setTargetImageKey] = useState(`targetImage-${Date.now()}.jpg`);

  const [livenessConfidence, setLivenessConfidence] = useState(null);// Estado para almacenar la confianza de liveness  

  const [address, setAddress] = useState(null);

  const webcamRef = useRef(null);
  const reader = new FileReader();

  /// liveness en step 2
  const [showNoLivenessMessage, setShowNoLivenessMessage] = useState(false);
  const [livenessData, setLivenessData] = useState(null);
  const [loadingLiveness, setLoadingLiveness] = useState(true);

  const getLocation = () => {
    if (navigator.geolocation) {
      console.log('-----navigator.geolocation: ');
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          // Llamada a la API de Nominatim para obtener la dirección
          try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
            const data = await response.json();
            const address = data.display_name;
            setAddress(address);
          } catch (error) {
            console.error('Error with reverse geocoding:', error);
          }
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const data = await response.json();
        const projectVersion = data.projectVersion;
        setProjectVersionArn(projectVersion);
      } catch (error) {
        console.error('Error loading config:', error);
      }
    };
    //getLocation();
    fetchConfig();
    
  }, []);


  // Handler para cambio de archivo o captura desde webcam
  const handleFileChange = async (event, fromWebcam = false) => {
    setLoadingFile(true);
    setDetectedText([]);
    setLabels([]);
    setFaceMatch(null);
    setImage(null);
    let file;
    let newImageKey;

    if (fromWebcam) {
      //Procesa la imagen capturada desde la webcam
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        file = dataURLtoBlob(imageSrc);
        newImageKey = `sourceImage-${Date.now()}.jpg`; // Clave única para la imagen de la webcam
        setSourceImageKey(newImageKey); // Actualiza el estado con la nueva clave para la imagen del documento
      } else {
        console.log('The image could not be captured from the webcam.');
        setLoadingFile(false);
        return;
      }
    } else {
      // Procesa el archivo seleccionado
      file = event.target.files[0];
      if (!file) {
        console.log('Selecciona un archivo para cargar.');
        setLoadingFile(false);
        return;
      }
      newImageKey = `sourceImage-${Date.now()}.jpg`; // Clave única para la imagen de archivo
      setSourceImageKey(newImageKey); // Actualiza el estado con la nueva clave para la imagen del documento
    }

    try {
      await uploadToS3(file, newImageKey); // Usa la nueva clave para la carga
      console.log(`File ${newImageKey} uploaded correctly.`);
      setSelectedFile(file);
      const labels = await detectLabels(newImageKey, bucketName);// Detecta customlabels (documentos) en la imagen cargada
      const textDetections = await detectText(newImageKey, bucketName);

      reader.onloadend = () => {
        setImage(reader.result);
        setLoadingFile(false);
        setVisible(true);
        setLabels(labels);
        setDetectedText(textDetections);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoadingFile(false);
    }
  };


  async function createSessionLiveness() {
    const rekognitionClient = new AWS.Rekognition();
    const params = {
      //ClientRequestToken: 'token-único-para-cada-solicitud', // Opcional pero recomendado para idempotencia
      //KmsKeyId: 'tu-kms-key-id', // Opcional, para encriptar las imágenes almacenadas
      Settings: { // Opcional, para configurar el almacenamiento de imágenes y el límite de imágenes de auditoría
        AuditImagesLimit: 2, // Puedes especificar de 0 a 4
        OutputConfig: {
          S3Bucket: bucketName,
          S3KeyPrefix: 'liveness-sessions/'
        }
      }
    };

    try {
      const response = await rekognitionClient.createFaceLivenessSession(params).promise();
      console.log("SessionId:", response.SessionId);
      return response.SessionId; // Usar este SessionId para futuras operaciones en la sesión

    } catch (error) {
      console.error("Error starting liveness session:", error);
      // Asegúrate de manejar los errores adecuadamente en tu aplicación
      return null;
    }
  }


  useEffect(() => {
    const fetchCreateLiveness = async () => {
      if (step === 2) {
        const sessionId = await createSessionLiveness();
        console.log('Liveness session ID:', sessionId);
        setLivenessData({ sessionId });
        setLoadingLiveness(false);
        await fetchFaceLivenessSessionResults(sessionId);

        // Asumir inicialmente que no hay liveness hasta que se demuestre lo contrario
        // Aquí deberías realizar la verificación real de liveness usando sessionId
        setShowNoLivenessMessage(true); //el mensaje se pondría para indicar al usuario que debe ponerse frente a la cámara

      }
    };
    fetchCreateLiveness();
  }, [step]);

  // Función  para obtener los resultados de la sesión de liveness
  const fetchFaceLivenessSessionResults = async (sessionId) => {
    const rekognitionClient = new AWS.Rekognition();
    const params = {
      SessionId: sessionId,
    };
    try {
      const response = await rekognitionClient.getFaceLivenessSessionResults(params).promise();
      console.log("Face Liveness session results:", response);
      if (response.Status === 'SUCCEEDED' && response.Confidence) {
        console.log("Liveness confidence:", response.Confidence);
        // Aquí puedes proceder basado en la confianza.
      } else {
        console.log("Liveness not confirmed or session not completed");
        // Manejar adecuadamente según tu lógica de negocio.
      }
    } catch (error) {
      console.error("Error retrieving Face Liveness results:", error);
    }
  };

  // Otras funciones como uploadToS3, dataURLtoBlob, obtenerImagenParamsS3, detectText, compareFaces...


  //-------------------------------detectText------------------------------
  // Función para detectar texto con AWS Rekognition
  const detectText = async (imageKey, bucketName) => {
    const rekognitionClient = new AWS.Rekognition();
    const params = {
      Image: {
        S3Object: {
          Bucket: bucketName,
          Name: imageKey
        }
      }
    };

    try {
      const response = await rekognitionClient.detectText(params).promise();

      // Check if response.TextDetections is an array and not empty before proceeding
      if (Array.isArray(response.TextDetections) && response.TextDetections.length > 0) {
        // Filter TextDetections to include only items with Type "LINE"
        const lineDetections = response.TextDetections.filter(item => item.Type === "LINE");

        // Map lineDetections to extract desired information
        const textDetails = lineDetections.map((detection) => ({
          DetectedText: detection.DetectedText,
          Confidence: detection.Confidence,
          Id: detection.Id,
          ParentId: detection.ParentId,
          Type: detection.Type,
          Geometry: detection.Geometry,
        }));

        console.log("-----El retorno del text:", textDetails);

        return textDetails;
      } else {
        console.log("No se encontraron detecciones de texto o no se detectaron líneas.");
        return []; // Return an empty array or handle the absence of detections as needed
      }
    } catch (error) {
      console.error("Error al detectar texto:", error);
      throw error;
    }
  };

  //---------------------inicio dataURLtoBlob-----------------------------
  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  //------------------------inicio obtenerImagenS3------------------------
  const obtenerImagenParamsS3 = async (sourceImageKey, targetImageKey, bucketName) => {
    try {
      const s3 = new AWS.S3();

      // Download images concurrently using Promise.all
      const [sourceImageData, targetImageData] = await Promise.all([
        s3.getObject({ Bucket: bucketName, Key: sourceImageKey }).promise(),
        s3.getObject({ Bucket: bucketName, Key: targetImageKey }).promise(),
      ]);

      // Extract image data directly for efficiency
      const sourceImageBytes = sourceImageData.Body;
      const targetImageBytes = targetImageData.Body;

      // Create comparison parameters (assuming no modifications needed)
      const params = {
        SourceImage: { Bytes: sourceImageBytes },
        TargetImage: { Bytes: targetImageBytes },
        SimilarityThreshold: 80
      };
      return params;
    } catch (err) {
      console.error("Error downloading images from S3:", err);
    }
  }


  //-------------------------------CompareFaces--------------------------------------

  const compareFaces = async (sourceImageKey, targetImageKey, bucketName, setFaceMatch, setIsPerson) => {
    const rekognitionClient = new AWS.Rekognition();
    let coincidencia = '';
    let detalle = '';
    let similitud = '0%';
    setSimilarity(similitud);
    const params = await obtenerImagenParamsS3(sourceImageKey, targetImageKey, bucketName);

    try {
      // Realizar la comparación de caras
      const response = await rekognitionClient.compareFaces(params).promise();
      if (response.FaceMatches && response.FaceMatches.length > 0) {
        const match = response.FaceMatches[0];
        console.log(`The faces match with a similarity of ${match.Similarity}%.`);
        coincidencia = t('biometrics_match_documents_file');
        detalle = t('thank_you_verifying_identity');
        similitud = match.Similarity.toFixed(2) + '%';
        setFaceMatchExito(coincidencia);
        setFaceMatch(detalle);
        setSimilarity(similitud);
      } else {
        console.log("No matches found.");
        coincidencia = t('no_match_identity_documents_file');
        detalle =  t('verify_documents_valid_updated');
        setFaceMatch(detalle);
        setFaceMatchError(coincidencia);
      }
      setIsPerson(true);
    } catch (error) {
      //console.error('Error uploading file:', error);
      if (error.name === 'InvalidParameterException') {
        // Manejar el caso en el que no se detectó un documento
        console.log('Not a valid document.');
        coincidencia = t('no_valid_document_or_person_detected');
        setFaceMatch(detalle);
        setFaceMatchError(coincidencia);
      } else if (error.name === 'MultipleValidationErrors') {
        console.log("error parameters", params)
      } //else {
      //   // Manejar otros errores
      //   console.error('Se produjo un error inesperado en funcion compareFaces');
      // }
    }
  }

  // Subir archivo a S3
  const uploadToS3 = async (file, key) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    try {
      await s3.upload(params).promise();
      console.log(`File ${key} uploaded correctly`);
      return key; // Retorna el key del archivo subido
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };

  //el modelo de custom labels que hemos entrenando para detectar el tipo de documento
  const detectLabels = async (imageKey, bucketName) => {
    const rekognitionCustom = new AWS.Rekognition();
    console.log('-------projectVersionArn: ', projectVersionArn)
    if (projectVersionArn) {
      const params = {
        Image: {
          S3Object: {
            Bucket: bucketName,
            Name: imageKey
          }
        },
        MaxResults: 1,
        MinConfidence: 80,
        ProjectVersionArn: projectVersionArn,
      };
      try {
        const response = await rekognitionCustom.detectCustomLabels(params).promise();
        return response.CustomLabels.map(label => label.Name);
      } catch (error) {
        console.error("Error detencting custom labels:", error);
      }
    } else {
      console.error("Error tags projectVersionArn null:", projectVersionArn);
    }

  };



  const captureAndCompareFaces = async () => {
    setFaceMatch(null);
    setFaceMatchExito(null);
    setFaceMatchError(null);
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const imageBlob = dataURLtoBlob(imageSrc);
      const newTargetImageKey = `targetImage-${Date.now()}.jpg`; // Genera nueva clave para la imagen del rostro
      setTargetImageKey(newTargetImageKey); // Actualiza el estado con la nueva clave para la imagen del rostro
      await uploadToS3(imageBlob, newTargetImageKey); // Carga la imagen del rostro con la nueva clave
      await compareFaces(sourceImageKey, newTargetImageKey, bucketName, setFaceMatch);// Compara las dos imagenes cargadas
      setLoadingFace(false);
      setStep(3);
    }
  };

  // Función para cerrar modal
  const closeModal = () => {
    setIsOpen(false);
    setImage(null)
    setSelectedFile(null)
    setDetectedText([]);
    setFaceMatch(null);
    setAddress(null);
    setStep(0);
  };

  // Función para abrir modal
  const openModal = () => {
    getLocation();
    setIsOpen(true);
    setImage(null)
    setSelectedFile(null)
    setDetectedText([]);
    setLabels([]);
    setFaceMatch(null);
    setVisible(false);
    setFaceMatchExito(null);
    setFaceMatchError(null);
  };

  return (
    <>
      <header className="bg-white shadow grid grid-cols-2 ">
        <div className="mx-10 max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
          <h1 className="text-1xl font-bold tracking-tight text-gray-900">{t('identity_verification_video')}</h1>
        </div>
      </header>
      <Card>
        <div className="flex flex-col items-center justify-center p-12">
          <button
            type="button"
            onClick={openModal}
            className="px-4 py-2 mb-8 text-sm font-medium text-white bg-indigo-500 rounded-md bg-opacity-100 hover:bg-opacity-80"
          >
            {t('start')}
          </button>
          <img src={t('video_sign_instructions_image')} alt="how to" style={{ width: '478px', height: '485px' }} />
        </div></Card>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  {step === 0 && (

                    <div className="grid grid-cols-2 gap-10">
                      <Card className="mx-auto max-w-xs">
                        <div className="flex items-start space-x-6">

                          <div>
                            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">{t('upload')}</p>
                            <p className="text-xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">{t('upload_identity_document')}</p>
                            <img src="./upload-desktop.png" alt={t('upload')} className="mt-2 mb-4 flex justify-center" />
                            <div className="flex justify-center">
                              <Button icon={RiUploadCloud2Line} onClick={() => setStep(1)}>Upload</Button>
                            </div>

                          </div>
                        </div>
                      </Card>

                      <Card className="mx-auto max-w-xs">
                        <div className="flex items-start space-x-6">

                          <div>
                            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">{t('from_camera')}</p>
                            <p className="text-xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">{t('take_photo_identity_document')} </p>
                            <img src="./picture-mobile.png" alt="Hacer upload" className="mt-2 mb-4 flex justify-center" />
                            <div className="flex justify-center">
                              <Button icon={RiMobileDownloadLine} onClick={() => setStep(5)}>{t('camera')} </Button>
                            </div>

                          </div>
                        </div>
                      </Card>
                    </div>
                  )}

                  {step === 1 && (
                    <div>
                      <FaUpload className="text-indigo-500" /> <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {t('upload_identity_document')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <input type="file" accept="image/jpeg, image/jpg, image/png" onChange={(event) => handleFileChange(event)}
                        />
                        {loadingFile && <img src="./spinner.gif" alt="loadingFile" />}
                      </div>
                      <div className="mt-4">
                        {image && <img src={image} alt="Cargado" />}
                        <div> {labels && labels.length > 0 && (
                          <div>
                            <h4 className='text-xl mt-5 font-medium text-purple-900 '>{t('national_document_detected')}</h4>
                            <ul className='text-xl font-bold text-purple-900'>
                              {labels.map((label, index) => (
                                <li key={index}>{label}</li> // Aquí `label` es una cadena que representa el nombre de la etiqueta
                              ))}
                            </ul>
                            <hr className='my-4 border-t-2 border-gray-300' />
                          </div>
                        )} </div>
                      </div>

                      <div className="mt-4">
                        {visible ? (
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                            onClick={() => setStep(2)}
                          >
                            {t('next')}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  )}


                  {step === 2 && (
                    <div>
                      <Dialog.Title as="h2" className="text-lg justify-center font-medium leading-6 text-gray-900">
                        {t('place_face_centered_oval')}
                      </Dialog.Title>
                      <div className="mt-2">

                        <div className="flex justify-center items-center " >

                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={200}
                            videoConstraints={{ width: 300, height: 400, facingMode: "user" }}
                            style={{
                              transform: 'scaleX(-1)', //espejo de video
                              borderRadius: "50%",
                              transformOrigin: "center"
                            }}
                          />
                        </div>
                        {loadingFace && <img src="/spinner.gif" alt="loadingFace" />}


                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setLoadingFace(true);
                            captureAndCompareFaces();
                            //setStep(3);
                          }}
                        >
                          {t('validate_my_identity')}
                        </button>
                      </div>
                    </div>
                  )}

                  {step === 3 && (
                    <div>
                      {faceMatchExito &&
                        <div className="max-w-lg">
                          <div className="px-10 py-2 flex">
                            <img src="/exito.png" alt="exito" className="mr-4 w-32 h-32" />
                            <div className="text-green-500 text-2xl font-bold">{faceMatchExito}</div>
                          </div>
                          <div className="text-xl font-bold">{faceMatch}</div>
                        </div>
                      }
                      {faceMatchError &&
                        <div className="max-w-lg">
                          <div className="px-10 py-2 flex">
                            <img src="/error.png" alt="error" className="mr-4 w-32 h-32" />
                            <div className="text-red-500 text-2xl font-bold">{faceMatchError}</div>
                          </div>
                          <div className="text-xl font-bold">{faceMatch}</div>
                        </div>
                      }

                      <div className="mt-2">

                        <div className="w-full pt-6 pb-10">
                          <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                    <span>{t('validation_details')}</span>
                                    <FaChevronDown
                                      className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-purple-500`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500"> <div className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                    {FaceMatchDetail && <div>{FaceMatchDetail}</div>}</div>
                                    <div> {labels && labels.length > 0 && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>{t('national_document_detected')}</h4>
                                        <ul className='text-xl font-bold text-purple-900'>
                                          {labels.map((label, index) => (
                                            <li key={index}>{label}</li> // Aquí `label` es una cadena que representa el nombre de la etiqueta
                                          ))}
                                        </ul>
                                        <hr className='my-4 border-t-2 border-gray-300' />
                                      </div>
                                    )} </div>

                                    {similarity && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>{t('face_match')}</h4>
                                        <div className="text-xl font-bold">{similarity}</div>
                                        <hr className='my-4 border-t-2 border-gray-300' />
                                      </div>
                                    )}

                                    {address && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>{t('geolocation')}</h4>
                                        <p>{address}</p>
                                        <hr className='my-4 border-t-2 border-gray-300' />
                                      </div>
                                    )}

                                    {detectedText.length > 0 && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>{t('text_detected')}</h4>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                          <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontWeight: 'bold' }}>
                                            <div>{t('text')}</div>
                                            <div>{t('confidence')}</div>
                                          </li>
                                          {detectedText.map((text, index) => (
                                            <li key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                              <div>{text.DetectedText}</div>
                                              <div style={{ fontSize: 'smaller', color: 'gray' }}>{text.Confidence.toFixed(2)}%</div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}

                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>

                          </div>
                        </div>


                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          {t('close_window')}
                        </button>
                      </div>
                    </div>
                  )}


                  {step === 5 && (
                    <div>
                      <Dialog.Title as="h2" className="text-lg justify-center font-medium leading-6 text-gray-900">
                        {t('bring_document_close_camera')}
                      </Dialog.Title>
                      <div className="mt-2">

                        <div className="flex justify-center items-center " >
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={300}
                            videoConstraints={{ width: 400, height: 300, facingMode: "user" }}
                            style={{
                              transform: 'scaleX(-1)',
                              borderRadius: "10%",
                              transformOrigin: "center"
                            }}
                          />
                        </div>
                        {loadingFace && <img src="/spinner.gif" alt="loadingFace" />}


                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            handleFileChange(null, true); setStep(6);
                          }}
                        >
                          {t('capture')}
                        </button>
                      </div>
                    </div>
                  )}


                  {step === 6 && (
                    <div>
                      <Dialog.Title as="h2" className="text-lg justify-center font-medium leading-6 text-gray-900">
                       {t('upload_identity_document')}
                      </Dialog.Title>

                      {loadingFile && <img src="./spinner.gif" alt="loadingFile" />}
                      <div className="mt-4">
                        {image && <img src={image} alt="Loaded" />}
                        <div> {labels && labels.length > 0 && (
                          <div>
                            <h4 className='text-xl mt-5 font-medium text-purple-900 '>{t('national_document_detected')}</h4>
                            <ul className='text-xl font-bold text-purple-900'>
                              {labels.map((label, index) => (
                                <li key={index}>{label}</li> // Aquí `label` es una cadena que representa el nombre de la etiqueta
                              ))}
                            </ul>
                            <hr className='my-4 border-t-2 border-gray-300' />
                          </div>
                        )} </div>
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setStep(2);
                          }}
                        >
                         {t('continue')}
                        </button>
                      </div>
                    </div>
                  )}



                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default VideoSignature;