import { Grid, Icon } from '@tremor/react';
import { Flex } from 'antd';
import React, { useState } from 'react';
import { FaBell, FaCommentDots, FaUserAlt } from 'react-icons/fa';
//import { FaSignOutAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';




const MainMenu = ({ onLogout, onSelectOption }) => {
  const { t } = useTranslation();
  // States to manage hover for each dropdown menu
  const [showCommunicationsDropdown, setShowCommunicationsDropdown] = useState(false);
  const [showContactsDropdown, setShowContactsDropdown] = useState(false);
  const [showDocumentsDropdown, setShowDocumentsDropdown] = useState(false);
  const [showReportingDropdown, setShowReportingDropdown] = useState(false);
  const [showUserProfileDropdown, setShowUserProfileDropdown] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
   // console.log(`Language changed to: ${lng}`); // Verifica si esto se imprime en la consola
  };

  return (
    <>
      <div className="min-h-full">
        <nav className="bg-[#ef4b26]">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-12 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <a href="#" className="-m-1.5 p-1.5" onClick={() => onSelectOption(0)} >
                    <img className="h-8 w-auto sm:h-8" src="https://imgcdn.email-platform.com/venturestars/60px-MenuTop_2.png" alt="DANAconnect" />
                  </a>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {/* Dropdown for Communications */}
                    <div className="relative" onMouseEnter={() => setShowCommunicationsDropdown(true)} onMouseLeave={() => setShowCommunicationsDropdown(false)}>
                      <a href="#" className="bg-orange-700 text-white rounded-md px-3 py-2 text-sm font-medium" aria-current="page">{t('communications')}</a>
                      {showCommunicationsDropdown && (
                        <div className="absolute left-0 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <a href="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-100">{t('conversations')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-100">{t('inbound')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-100">{t('message_audit')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-100">{t('email_editor')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => onSelectOption(2)}>{t('genai_templates')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => onSelectOption(1)}>{t('video_sign')}</a>
                          { /* <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => onSelectOption(7)}>Video Firma V2</a>*/}
                        </div>
                      )}
                    </div>

                    {/* Dropdown for Contacts */}
                    <div className="relative" onMouseEnter={() => setShowContactsDropdown(true)} onMouseLeave={() => setShowContactsDropdown(false)}>
                      <a href="#" className="text-orange-300 hover:bg-orange-700 hover:text-white-300 rounded-md px-3 py-2 text-sm font-medium">{t('contacts')}</a>
                      {/* {showContactsDropdown && (
                        <div className="absolute left-0 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Listas de Contacto</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Administrador de data</a>
                        </div>
                      )} */}
                    </div>


                    {/* Template for Documents */}
                    <div className="relative" onMouseEnter={() => setShowDocumentsDropdown(true)} onMouseLeave={() => setShowDocumentsDropdown(false)}>
                      <a href="#" className="text-white hover:bg-orange-700 hover:text-white-300 rounded-md px-3 py-2 text-sm font-medium">{t('documents')}</a>
                      {showDocumentsDropdown && (
                        <div className="absolute left-0 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <a href="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-100">{t('document_manager')}</a>
                          <a href="http://deploy-serverless-pdf-chat.s3-website-us-east-1.amazonaws.com" target="_blank" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{t('chat_pdf')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => onSelectOption(3)}> {t('national_id_v1')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => onSelectOption(8)}>  {t('national_id_v2')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => onSelectOption(6)}>  {t('idp')}</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => onSelectOption(5)}>  {t('videosign_history')}</a>
                        </div>
                      )}
                    </div>
                    {/* Template for Reporting */}
                    <div className="relative" onMouseEnter={() => setShowReportingDropdown(true)} onMouseLeave={() => setShowReportingDropdown(false)}>
                      <a href="#" className="text-orange-300 hover:bg-orange-700 hover:text-white-300 rounded-md px-3 py-2 text-sm font-medium">{t('reports')}</a>
                      {/* {showReportingDropdown && (
                        <div className="absolute left-0 mt-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Reportes</a>
                          <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">DMARC</a>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
                        
              {/* Template iconos */}
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {/* <button type="button" className="relative rounded-full bg-orange-700 p-2 text-white hover:text-white-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-800">
              <span className="absolute -inset-1.5"></span>
              <span className="sr-only">Notificaciones</span>
             
              <FaBell/> 
            </button>  */}
                  {/* <div className="ml-4 flex items-center md:ml-4">
            <button type="button" className="relative rounded-full bg-orange-700 p-2 text-white hover:text-white-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-800">
              <span className="absolute -inset-1.5"></span>
              <span className="sr-only">Chat Soporte</span>
              <FaCommentDots/>
            </button></div> */}


            
  <div className="grid grid-cols-2 gap-2">
    <button type="button" className="relative rounded-full bg-orange-700 p-1 text-sm text-white hover:text-white-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-800"  onClick={() => changeLanguage('en')}>English</button>
    <button  className="relative rounded-full bg-orange-700 p-1 text-sm text-white hover:text-white-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-800" onClick={() => changeLanguage('es')}>Español</button>
  </div>
                  {/* <!-- Profile dropdown --> */}
                  <div className="relative ml-4" onMouseEnter={() => setShowUserProfileDropdown(true)} onMouseLeave={() => setShowUserProfileDropdown(false)}>
                    <div>
                      <button type="button" className="relative rounded-full bg-orange-700 p-2 text-white hover:text-white-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                        <span className="absolute -inset-1.5"></span>
                        <span className="sr-only">Open user menu</span>
                        <FaUserAlt /> </button>
                    </div>
                    {showUserProfileDropdown && (
                      <Grid numItemsMd={2}>
                        <div className="absolute right-0 z-1 mt-0 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                          <a href="#"
                            className="block px-4 py-0 text-sm text-gray-700"
                            role="menuitem"
                            tabIndex="-1"
                            id="user-menu-item-2"
                            onClick={() => onLogout()}
                          >
                            <Flex justify='end'>
                            {t('logout')} 
                            </Flex>    </a>
                        </div>
                      </Grid>
                    )}

                  </div>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">

                <button type="button" className="relative inline-flex items-center justify-center rounded-md bg-orange-800 p-2 text-gray-400 hover:bg-orange-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" aria-controls="mobile-menu" aria-expanded="false">
                  <span className="absolute -inset-0.5"></span>
                  <span className="sr-only">Abrir el menú</span>

                  <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>

                  <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>


            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default MainMenu;