import React, { useState, useEffect, useRef, Fragment } from 'react';
import Webcam from 'react-webcam';
import { AWS } from './Login.js';
import { Dialog, Transition } from '@headlessui/react';
import { Disclosure } from '@headlessui/react';
import { FaChevronDown, FaUpload } from 'react-icons/fa';
import { Card, Button } from '@tremor/react';
import { RiMobileDownloadLine, RiUploadCloud2Line } from '@remixicon/react';
import { BedrockRuntimeClient, InvokeModelCommand } from '@aws-sdk/client-bedrock-runtime';


const bucketName = process.env.REACT_APP_BUCKET_NAME;
const sourceImageKey = `sourceImage-${Date.now()}.jpg`;
const targetImageKey = `targetImage-${Date.now()}.jpg`;


//=============================Main VideoSignature=========================

const VideoSignatureV2 = () => {
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [detectedText, setDetectedText] = useState([]);
  const [FaceMatchDetail, setFaceMatchDetail] = useState(null);
  const [faceMatch, setFaceMatch] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingFace, setLoadingFace] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isPerson, setIsPerson] = useState(false);
  const [labels, setLabels] = useState([]);
  const [projectVersionArn, setProjectVersionArn] = useState(null);
  const [faceMatchExito, setFaceMatchExito] = useState(null);
  const [faceMatchError, setFaceMatchError] = useState(null);
  const [similarity, setSimilarity] = useState(null);
  const [formatedText, setFormatedText] = useState([]);

  const [sourceImageKey, setSourceImageKey] = useState(`sourceImage-${Date.now()}.jpg`);
  const [targetImageKey, setTargetImageKey] = useState(`targetImage-${Date.now()}.jpg`);

  const [livenessConfidence, setLivenessConfidence] = useState(null);// Estado para almacenar la confianza de liveness  
  const [address, setAddress] = useState(null);

  const webcamRef = useRef(null);
  const reader = new FileReader();

  /// liveness en step 2
  const [showNoLivenessMessage, setShowNoLivenessMessage] = useState(false);
  const [livenessData, setLivenessData] = useState(null);
  const [loadingLiveness, setLoadingLiveness] = useState(true);

  const getLocation = () => {
    if (navigator.geolocation) {
      console.log('-----navigator.geolocation: ');
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          // Llamada a la API de Nominatim para obtener la dirección
          try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
            const data = await response.json();
            const address = data.display_name;
            setAddress(address);
          } catch (error) {
            console.error('Error with reverse geocoding:', error);
          }
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const data = await response.json();
        const projectVersion = data.projectVersion;
        setProjectVersionArn(projectVersion);
      } catch (error) {
        console.error('Error loading config:', error);
      }
    };

    fetchConfig();
  }, []);


  // Handler para cambio de archivo o captura desde webcam
  const handleFileChange = async (event, fromWebcam = false) => {
    setLoadingFile(true);
    setDetectedText([]);
    setLabels([]);
    setFaceMatch(null);
    setImage(null);
    let file;
    let newImageKey;

    if (fromWebcam) {
      // Procesa la imagen capturada desde la webcam
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        file = dataURLtoBlob(imageSrc);
        newImageKey = `sourceImage-${Date.now()}.jpg`; // Clave única para la imagen de la webcam
        setSourceImageKey(newImageKey); // Actualiza el estado con la nueva clave para la imagen del documento
      } else {
        console.log('No se pudo capturar la imagen desde la webcam.');
        setLoadingFile(false);
        return;
      }
    } else {
      // Procesa el archivo seleccionado
      file = event.target.files[0];
      if (!file) {
        console.log('Selecciona un archivo para cargar.');
        setLoadingFile(false);
        return;
      }
      newImageKey = `sourceImage-${Date.now()}.jpg`; // Clave única para la imagen de archivo
      setSourceImageKey(newImageKey); // Actualiza el estado con la nueva clave para la imagen del documento
    }

    try {
      await uploadToS3(file, newImageKey); // Usa la nueva clave para la carga
      console.log(`Archivo ${newImageKey} subido correctamente`);
      setSelectedFile(file);
      const labels = await detectLabels(newImageKey, bucketName);// Detecta customlabels (documentos) en la imagen cargada
      const textDetections = await detectText(newImageKey, bucketName);
      const textoFormateado = await formatearTexto(textDetections);

      reader.onloadend = () => {
        setImage(reader.result);
        setLoadingFile(false);
        setVisible(true);
        setLabels(labels);
        setDetectedText(textDetections);
        setFormatedText(textoFormateado);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoadingFile(false);
    }
  };


  async function createSessionLiveness() {
    const rekognitionClient = new AWS.Rekognition();
    const params = {
      //ClientRequestToken: 'token-único-para-cada-solicitud', // Opcional pero recomendado para idempotencia
      //KmsKeyId: 'tu-kms-key-id', // Opcional, para encriptar las imágenes almacenadas
      Settings: { // Opcional, para configurar el almacenamiento de imágenes y el límite de imágenes de auditoría
        AuditImagesLimit: 2, // Puedes especificar de 0 a 4
        OutputConfig: {
          S3Bucket: bucketName,
          S3KeyPrefix: 'liveness-sessions/'
        }
      }
    };

    try {
      const response = await rekognitionClient.createFaceLivenessSession(params).promise();
      console.log("SessionId:", response.SessionId);
      return response.SessionId; // Usar este SessionId para futuras operaciones en la sesión

    } catch (error) {
      console.error("Error al iniciar la sesión de liveness:", error);
      // Asegúrate de manejar los errores adecuadamente en tu aplicación
      return null;
    }
  }


  useEffect(() => {
    const fetchCreateLiveness = async () => {
      if (step === 2) {
        const sessionId = await createSessionLiveness();
        console.log('ID de sesión de liveness:', sessionId);
        setLivenessData({ sessionId });
        setLoadingLiveness(false);
        await fetchFaceLivenessSessionResults(sessionId);

        // Asumir inicialmente que no hay liveness hasta que se demuestre lo contrario
        // Aquí deberías realizar la verificación real de liveness usando sessionId
        setShowNoLivenessMessage(true); //el mensaje se pondría para indicar al usuario que debe ponerse frente a la cámara

      }
    };
    fetchCreateLiveness();
  }, [step]);

  // Función  para obtener los resultados de la sesión de liveness
  const fetchFaceLivenessSessionResults = async (sessionId) => {
    const rekognitionClient = new AWS.Rekognition();
    const params = {
      SessionId: sessionId,
    };
    try {
      const response = await rekognitionClient.getFaceLivenessSessionResults(params).promise();
      console.log("Resultados de la sesión de Face Liveness:", response);
      if (response.Status === 'SUCCEEDED' && response.Confidence) {
        console.log("Confianza de Liveness:", response.Confidence);
        // Aquí puedes proceder basado en la confianza.
      } else {
        console.log("Liveness no confirmado o sesión no completada");
        // Manejar adecuadamente según tu lógica de negocio.
      }
    } catch (error) {
      console.error("Error al obtener resultados de Face Liveness:", error);
    }
  };

  // Otras funciones como uploadToS3, dataURLtoBlob, obtenerImagenParamsS3, detectText, compareFaces...

  const formatearTextoSiEsFecha = (detectedText) => {

    // Actualizamos el regex para que coincida con el formato yyyy-mm-dd
    const dateRegex = /(\d{2})[\/\- ](\d{2})[\/\- ](\d{4})/;
    const dateRegex2 = /(\d{4})([\-\/\s])(\d{2})\2(\d{2})/;
    const dateRegex3 = /(0[1-9]|1[0-2])[-\/](\d{4})/;
    const dateRegex4 = /vigencia.*?(\d{4})\b/i;
    const dateRegex5 = /\b(\d{1,2})\s(ENERO|FEBRERO|MARZO|ABRIL|MAYO|JUNIO|JULIO|AGOSTO|SEPTIEMBRE|OCTUBRE|NOVIEMBRE|DICIEMBRE)\s(\d{4})\b/;

    let latestDate = new Date(0); // Inicializa a una fecha antigua

    const matches = detectedText.match(dateRegex);
    const matches2 = detectedText.match(dateRegex2);
    const matches3 = detectedText.match(dateRegex3);
    const matches4 = detectedText.match(dateRegex4);
    const matches5 = detectedText.match(dateRegex5);
    let date = null;
    let result = detectedText;

    if (matches !== null) {
      date = new Date(matches[3], matches[2] - 1, matches[1]);
    }
    if (matches2 !== null) {
      date = new Date(matches2[1], matches2[3] - 1, matches2[4]);
    }
    if (matches3 !== null && matches === null) {
      date = new Date(matches3[2], matches3[1] - 1, 1);
    }
    if (matches4 !== null) {
      const year = parseInt(matches4[1]);
      // Crear una fecha con el año extraído (1 de enero de ese año)
      date = new Date(year, 0, 1);
    }
    if (date > latestDate) {
      latestDate = date;
    }
    // Si encontramos una fecha válida, actualiza el item.DetectedText según sea necesario
    if (latestDate > new Date(0)) {
      result = formatDate(latestDate);
    }
    if (matches5 !== null) {
      result = matches5[0];
    }

    return result;
  };

  //formatea la fecha a dd-mm-yyyy
  const formatDate = (date) => {
    // Obtener día, mes y año en el formato dd-mm-yyyy
    let day = date.getDate().toString().padStart(2, '0');
    let formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0'); // Sumar 1 al mes porque en JavaScript los meses van de 0 a 11
    let formattedYear = date.getFullYear();

    // Construir la cadena de fecha en el formato dd-mm-yyyy
    const formattedDate = `${day}-${formattedMonth}-${formattedYear}`;
    return formattedDate;
  };


  //-------------------------------detectText------------------------------
  // Función para detectar texto con AWS Rekognition
  const detectText = async (imageKey, bucketName) => {
    const rekognitionClient = new AWS.Rekognition();
    const params = {
      Image: {
        S3Object: {
          Bucket: bucketName,
          Name: imageKey
        }
      }
    };

    try {
      const response = await rekognitionClient.detectText(params).promise();

      // Check if response.TextDetections is an array and not empty before proceeding
      if (Array.isArray(response.TextDetections) && response.TextDetections.length > 0) {
        // Filter TextDetections to include only items with Type "LINE"
        const lineDetections = response.TextDetections.filter(item => item.Type === "LINE");

        // Map lineDetections to extract desired information
        const textDetails = lineDetections.map((detection) => ({
          DetectedText: formatearTextoSiEsFecha(detection.DetectedText),
          Confidence: detection.Confidence,
          Id: detection.Id,
          ParentId: detection.ParentId,
          Type: detection.Type,
          //Geometry: detection.Geometry,
        }));

        return textDetails;
      } else {
        console.log("No se encontraron detecciones de texto o no se detectaron líneas.");
        return []; // Return an empty array or handle the absence of detections as needed
      }
    } catch (error) {
      console.error("Error al detectar texto:", error);
      throw error;
    }
  };

  // Initialize and configure BedrockRuntimeClient
  const client = new BedrockRuntimeClient({
    region: process.env.REACT_APP_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
  });
  //-------------------formatearTexto con Bedrock-------------------------
  /// Invocar el Modelo BEDROCK Anthropic Claude v2.1
  async function formatearTexto(textDetails) {
    const textDetailsString = JSON.stringify(textDetails);
    console.log('--------los datos que llegan al prompt: ', textDetails)

    ////////////----------Creación del prompt y envío del request a bedrock
    // Construcción del prompt con instrucciones claras
    const todayDate = new Date().toISOString().split('T')[0];

    const constructedModelPrompt = `Human: A continuación, se presentan los detalles extraídos de un documento de identificación. Los datos son los siguientes:\n\n${textDetailsString}\n\nOrganiza la información en formato JSON siguiendo el esquema: Tipo de documento, País, Número de documento, Apellidos, Nombres, Fecha de nacimiento, Edad, Fecha de vencimiento. El número de documento en Venezuela comienza con 'V' seguido de números, como en 'V 28.061.153', la V solo va si el tipo de documentos es una Cédula de identidad y el pais Venezuela. La fecha de vencimiento debe ser la fecha más reciente de entre todas las fechas presentes en los datos y puede estar en cualquier formato. Además, incluye un campo de Status que indique si el documento está VENCIDO o VIGENTE, basándote en la fecha de vencimiento comparada con la fecha actual ${todayDate}. La edad debe calcularse como la diferencia entre la fecha actual y la fecha de nacimiento. Si algún dato no está disponible, omítelo en la respuesta en lugar de dejarlo en blanco. Usa camel case para las claves en el JSON y proporciona la información en español.\n\nAssistant:`;
    
    const modelId = 'anthropic.claude-v2:1';
    const payload = {
      prompt: constructedModelPrompt,
      max_tokens_to_sample: 1000,
      temperature: 0.3,
      top_k: 100,
      top_p: 0.3,
      stop_sequences: [],
      anthropic_version: 'bedrock-2023-05-31',
    };
    try {
      const data = await client.send(new InvokeModelCommand({
        body: JSON.stringify(payload),
        modelId,
        contentType: 'application/json',
        accept: 'application/json'
      }));

      const jsonString = new TextDecoder().decode(data.body);
      const response = JSON.parse(jsonString);

      // Extraer el JSON formateado
      const { completion } = response;
      const startIndex = completion.indexOf('```json') + '```json\n'.length;
      const endIndex = completion.lastIndexOf('\n```');
      const formattedJson = completion.substring(startIndex, endIndex);

      // Parsear el JSON
      const parsedJson = JSON.parse(formattedJson);

      console.log(parsedJson);
      return parsedJson;
    } catch (err) {
      console.error('Error:', err);
      return null;
    } finally {
    }
  }

  //---------------------inicio dataURLtoBlob-----------------------------
  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  //------------------------inicio obtenerImagenS3------------------------
  const obtenerImagenParamsS3 = async (sourceImageKey, targetImageKey, bucketName) => {
    try {
      const s3 = new AWS.S3();

      // Download images concurrently using Promise.all
      const [sourceImageData, targetImageData] = await Promise.all([
        s3.getObject({ Bucket: bucketName, Key: sourceImageKey }).promise(),
        s3.getObject({ Bucket: bucketName, Key: targetImageKey }).promise(),
      ]);

      // Extract image data directly for efficiency
      const sourceImageBytes = sourceImageData.Body;
      const targetImageBytes = targetImageData.Body;

      // Create comparison parameters (assuming no modifications needed)
      const params = {
        SourceImage: { Bytes: sourceImageBytes },
        TargetImage: { Bytes: targetImageBytes },
        SimilarityThreshold: 80
      };
      return params;
    } catch (err) {
      console.error("Error downloading images from S3:", err);
    }
  }


  //-------------------------------CompareFaces--------------------------------------

  const compareFaces = async (sourceImageKey, targetImageKey, bucketName, setFaceMatch, setIsPerson) => {
    const rekognitionClient = new AWS.Rekognition();
    let coincidencia = '';
    let detalle = '';
    let similitud = '0%';
    setSimilarity(similitud);
    const params = await obtenerImagenParamsS3(sourceImageKey, targetImageKey, bucketName);

    try {
      // Realizar la comparación de caras
      const response = await rekognitionClient.compareFaces(params).promise();
      if (response.FaceMatches && response.FaceMatches.length > 0) {
        const match = response.FaceMatches[0];
        console.log(`Las caras coinciden con una similitud del ${match.Similarity}%.`);
        coincidencia = 'La biometría coincide con los documentos en su dossier.';
        detalle = 'Gracias por verificar su identidad, esto ayuda a mantener la seguridad de sus operaciones.';
        similitud = match.Similarity.toFixed(2) + '%';
        setFaceMatchExito(coincidencia);
        setFaceMatch(detalle);
        setSimilarity(similitud);
      } else {
        console.log("No se encontraron coincidencias.");
        coincidencia = 'No se encontró coincidencia con los documentos de identidad en su dossier.';
        detalle = 'Verifique que los documentos en su dossier sean válidos y estén actualizados con su identidad. Es importante que la información coincida para garantizar la seguridad de la transacción.'
        setFaceMatch(detalle);
        setFaceMatchError(coincidencia);
      }
      setIsPerson(true);
    } catch (error) {
      //console.error('Error uploading file:', error);
      if (error.name === 'InvalidParameterException') {
        // Manejar el caso en el que no se detectó un documento
        console.log('No se detectó un documento válido.');
        coincidencia = 'No se detectó un documento de identidad válido, o no hay una persona frente a la cámara.';
        setFaceMatch(detalle);
        setFaceMatchError(coincidencia);
      } else if (error.name === 'MultipleValidationErrors') {
        console.log("error con parametros", params)
      }
    }
  }

  // Subir archivo a S3
  const uploadToS3 = async (file, key) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    try {
      await s3.upload(params).promise();
      console.log(`Archivo ${key} subido correctamente`);
      return key; // Retorna el key del archivo subido
    } catch (error) {
      console.error('Error al subir archivo:', error);
    }
  };

  //el modelo de custom labels que hemos entrenando para detectar el tipo de documento
  const detectLabels = async (imageKey, bucketName) => {
    const rekognitionCustom = new AWS.Rekognition();
    console.log('-------projectVersionArn: ', projectVersionArn)
    if (projectVersionArn) {
      const params = {
        Image: {
          S3Object: {
            Bucket: bucketName,
            Name: imageKey
          }
        },
        MaxResults: 1,
        MinConfidence: 80,
        ProjectVersionArn: projectVersionArn,
      };
      try {
        const response = await rekognitionCustom.detectCustomLabels(params).promise();
        return response.CustomLabels.map(label => label.Name);
      } catch (error) {
        console.error("Error al detectar etiquetas custom:", error);
      }
    } else {
      console.error("Error al detectar etiquetas projectVersionArn null:", projectVersionArn);
    }

  };



  const captureAndCompareFaces = async () => {
    setFaceMatch(null);
    setFaceMatchExito(null);
    setFaceMatchError(null);
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const imageBlob = dataURLtoBlob(imageSrc);
      const newTargetImageKey = `targetImage-${Date.now()}.jpg`; // Genera nueva clave para la imagen del rostro
      setTargetImageKey(newTargetImageKey); // Actualiza el estado con la nueva clave para la imagen del rostro
      await uploadToS3(imageBlob, newTargetImageKey); // Carga la imagen del rostro con la nueva clave
      await compareFaces(sourceImageKey, newTargetImageKey, bucketName, setFaceMatch);// Compara las dos imagenes cargadas
      setLoadingFace(false);
      setStep(3);
    }
  };

  // Función para cerrar modal
  const closeModal = () => {
    setIsOpen(false);
    setImage(null)
    setSelectedFile(null)
    setDetectedText([]);
    setFormatedText(null);
    setFaceMatch(null);
    setStep(0);
  };

  // Función para abrir modal
  const openModal = () => {
    getLocation();
    setIsOpen(true);
    setImage(null)
    setSelectedFile(null)
    setDetectedText([]);
    setLabels([]);
    setFaceMatch(null);
    setVisible(false);
    setFaceMatchExito(null);
    setFaceMatchError(null);
    setFormatedText(null);
  };

  return (
    <>
      <header className="bg-white shadow grid grid-cols-2 ">
        <div className="mx-10 max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
          <h1 className="text-1xl font-bold tracking-tight text-gray-900">Validación de identidad con video</h1>
        </div>
      </header>
      <Card>
        <div className="flex flex-col items-center justify-center p-12">
          <button
            type="button"
            onClick={openModal}
            className="px-4 py-2 mb-8 text-sm font-medium text-white bg-indigo-500 rounded-md bg-opacity-100 hover:bg-opacity-80"
          >
            Comenzar
          </button>
          <img src="/instrucciones.png" alt="Instrucciones" style={{ width: '478px', height: '485px' }} />
        </div></Card>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">


                  {step === 0 && (

                    <div className="grid grid-cols-2 gap-10">
                      <Card className="mx-auto max-w-xs">
                        <div className="flex items-start space-x-6">

                          <div>
                            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Hacer Upload</p>
                            <p className="text-xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">Sube tu documento de identidad</p>
                            <img src="./upload-desktop.png" alt="Hacer upload" className="mt-2 mb-4 flex justify-center" />
                            <div className="flex justify-center">
                              <Button icon={RiUploadCloud2Line} onClick={() => setStep(1)}>Upload </Button>
                            </div>

                          </div>
                        </div>
                      </Card>

                      <Card className="mx-auto max-w-xs">
                        <div className="flex items-start space-x-6">

                          <div>
                            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Desde la cámara</p>
                            <p className="text-xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">Toma foto a tu documento de identidad </p>
                            <img src="./picture-mobile.png" alt="Hacer upload" className="mt-2 mb-4 flex justify-center" />
                            <div className="flex justify-center">
                              <Button icon={RiMobileDownloadLine} onClick={() => setStep(5)}>Cámara </Button>
                            </div>

                          </div>
                        </div>
                      </Card>
                    </div>
                  )}

                  {step === 1 && (
                    <div>
                      <FaUpload className="text-indigo-500" /> <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Sube tu documento de identidad
                      </Dialog.Title>
                      <div className="mt-2">
                        <input type="file" accept="image/jpeg, image/jpg, image/png" onChange={(event) => handleFileChange(event)}
                        />
                        {loadingFile && <img src="./spinner.gif" alt="loadingFile" />}
                      </div>
                      <div className="mt-4">
                        {image && <img src={image} alt="Cargado" />}
                        <div> {labels && labels.length > 0 && (
                          <div>
                            <h4 className='text-xl mt-5 font-medium text-purple-900 '>Documento Nacional Detectado</h4>
                            <ul className='text-xl font-bold text-purple-900'>
                              {labels.map((label, index) => (
                                <li key={index}>{label}</li> // Aquí `label` es una cadena que representa el nombre de la etiqueta
                              ))}
                            </ul>
                            <hr className='my-4 border-t-2 border-gray-300' />
                          </div>
                        )} </div>
                      </div>

                      <div className="mt-4">
                        {visible ? (
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                            onClick={() => setStep(2)}
                          >
                            Siguiente
                          </button>
                        ) : null}
                      </div>
                    </div>
                  )}


                  {step === 2 && (
                    <div>
                      <Dialog.Title as="h2" className="text-lg justify-center font-medium leading-6 text-gray-900">
                        Coloca tu rostro centrado en el óvalo
                      </Dialog.Title>
                      <div className="mt-2">

                        <div className="flex justify-center items-center " >

                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={200}
                            videoConstraints={{ width: 300, height: 400, facingMode: "user" }}
                            style={{
                              transform: 'scaleX(-1)', //espejo de video
                              borderRadius: "50%",
                              transformOrigin: "center"
                            }}
                          />
                        </div>
                        {loadingFace && <img src="/spinner.gif" alt="loadingFace" />}


                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setLoadingFace(true);
                            captureAndCompareFaces();
                            //setStep(3);
                          }}
                        >
                          Validar mi identidad
                        </button>
                      </div>
                    </div>
                  )}

                  {step === 3 && (
                    <div>
                      {faceMatchExito &&
                        <div className="max-w-lg">
                          <div className="px-10 py-2 flex">
                            <img src="/exito.png" alt="exito" className="mr-4 w-32 h-32" />
                            <div className="text-green-500 text-2xl font-bold">{faceMatchExito}</div>
                          </div>
                          <div className="text-xl font-bold">{faceMatch}</div>
                        </div>
                      }
                      {faceMatchError &&
                        <div className="max-w-lg">
                          <div className="px-10 py-2 flex">
                            <img src="/error.png" alt="error" className="mr-4 w-32 h-32" />
                            <div className="text-red-500 text-2xl font-bold">{faceMatchError}</div>
                          </div>
                          <div className="text-xl font-bold">{faceMatch}</div>
                        </div>
                      }

                      <div className="mt-2">

                        <div className="w-full pt-6 pb-10">
                          <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                    <span>Detalles de la validación</span>
                                    <FaChevronDown
                                      className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-purple-500`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500"> <div className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                    {FaceMatchDetail && <div>{FaceMatchDetail}</div>}</div>
                                    <div> {labels && labels.length > 0 && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>Documento Nacional Detectado</h4>
                                        <ul className='text-xl font-bold text-purple-900'>
                                          {labels.map((label, index) => (
                                            <li key={index}>{label}</li> // Aquí `label` es una cadena que representa el nombre de la etiqueta
                                          ))}
                                        </ul>
                                        <hr className='my-4 border-t-2 border-gray-300' />
                                      </div>
                                    )} </div>

                                    {similarity && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>Coincidencia de rostros</h4>
                                        <div className="text-xl font-bold">{similarity}</div>
                                        <hr className='my-4 border-t-2 border-gray-300' />
                                      </div>
                                    )}

                                    {formatedText && (
                                      <div>
                                        <h4 className='text-xl mt-5 mb-2 font-medium text-purple-900'>Datos</h4>
                                        <pre className='text-sm text-gray-700'>
                                          {Object.keys(formatedText).map((key) => (
                                            <div key={key} className='mb-1'>
                                              <span className='font-bold'>{key}: </span>
                                              <span>{typeof formatedText[key] === 'string' ? formatedText[key] : JSON.stringify(formatedText[key])}</span>
                                            </div>
                                          ))}
                                        </pre>
                                        <hr className='my-4 border-t-2 border-gray-300' />
                                      </div>
                                    )}

                                    {address && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>Geolocalización</h4>
                                        <p>{address}</p>
                                        <hr className='my-4 border-t-2 border-gray-300' />
                                      </div>
                                    )}

                                    {detectedText.length > 0 && (
                                      <div>
                                        <h4 className='text-xl mt-5 font-medium text-purple-900 '>Texto Detectado</h4>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                          <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontWeight: 'bold' }}>
                                            <div>Texto</div>
                                            <div>Confianza</div>
                                          </li>
                                          {detectedText.map((text, index) => (
                                            <li key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                              <div>{text.DetectedText}</div>
                                              <div style={{ fontSize: 'smaller', color: 'gray' }}>{text.Confidence.toFixed(2)}%</div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}

                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>

                          </div>
                        </div>


                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Cerrar la ventana
                        </button>
                      </div>
                    </div>
                  )}


                  {step === 5 && (
                    <div>
                      <Dialog.Title as="h2" className="text-lg justify-center font-medium leading-6 text-gray-900">
                        Acerca tu documento a la camara
                      </Dialog.Title>
                      <div className="mt-2">

                        <div className="flex justify-center items-center " >
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={300}
                            videoConstraints={{ width: 400, height: 300, facingMode: "user" }}
                            style={{
                              transform: 'scaleX(-1)',
                              borderRadius: "10%",
                              transformOrigin: "center"
                            }}
                          />
                        </div>
                        {loadingFace && <img src="/spinner.gif" alt="loadingFace" />}


                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            handleFileChange(null, true); setStep(6);
                          }}
                        >
                          Capturar
                        </button>
                      </div>
                    </div>
                  )}


                  {step === 6 && (
                    <div>
                      <Dialog.Title as="h2" className="text-lg justify-center font-medium leading-6 text-gray-900">
                        Carga tu documento de identidad
                      </Dialog.Title>

                      {loadingFile && <img src="./spinner.gif" alt="loadingFile" />}
                      <div className="mt-4">
                        {image && <img src={image} alt="Cargado" />}
                        <div> {labels && labels.length > 0 && (
                          <div>
                            <h4 className='text-xl mt-5 font-medium text-purple-900 '>Documento Nacional Detectado</h4>
                            <ul className='text-xl font-bold text-purple-900'>
                              {labels.map((label, index) => (
                                <li key={index}>{label}</li> // Aquí `label` es una cadena que representa el nombre de la etiqueta
                              ))}
                            </ul>
                            <hr className='my-4 border-t-2 border-gray-300' />
                          </div>
                        )} </div>
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setStep(2);
                          }}
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  )}



                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default VideoSignatureV2;