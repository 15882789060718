// App.js
import React, { useState } from 'react';
import Login from './components/Login';
import MainMenu from './components/MainMenu';
import Home from './components/home';
import VideoSignature from './components/VideoSignature';
import Demo2 from './components/Demo2';
import ValidarIdentidad from './components/ValidarIdentidad';
import HistoricoFirma from './components/HistoricoFirma';
import ProcesamientoInteligente from './components/ProcesamientoInteligente';

import VideoSignatureV2 from './components/VideoSignatureV2';
import ValidarIdentidadV2 from './components/ValidarIdentidadV2';
import './App.css';





function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleLogin = () => {
    setIsLoggedIn(true);
    setSelectedOption(0)
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setSelectedOption(null); // Limpiamos la opción seleccionada al hacer logout
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <div>
        {!isLoggedIn ? (
          <Login onLogin={handleLogin} />
        ) : (
          <div>
            <MainMenu onLogout={handleLogout} onSelectOption={handleSelectOption} />
            {selectedOption === 0 && <Home />}
            {selectedOption === 1 && <VideoSignature />}
            {selectedOption === 2 && <Demo2 />}
            {selectedOption === 3 && <ValidarIdentidad />}

            {selectedOption === 5 && <HistoricoFirma onSelectOption={handleSelectOption} />}
            {selectedOption === 6 && <ProcesamientoInteligente onSelectOption={handleSelectOption} />}
            {selectedOption === 7 && <VideoSignatureV2 />}
            {selectedOption === 8 && <ValidarIdentidadV2 />}
            
          </div>
        )}
      </div>
    </>
  );
}

export default App;
