import { AWS } from './Login.js';
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useTranslation } from 'react-i18next';
import i18n from "./i18n.js"; //  archivo de configuración de lenguaje
import { BedrockRuntimeClient, InvokeModelCommand } from '@aws-sdk/client-bedrock-runtime';
import {
    DateRangePicker,
    TextInput,
    Grid, Badge, Card, Title, Subtitle, Text, Flex, Button, Icon, Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell, TableFoot, TableFooterCell
} from "@tremor/react";
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, SearchIcon, ShieldExclamationIcon, XIcon, QuestionMarkCircleIcon, UploadIcon, PlusIcon, SaveIcon } from "@heroicons/react/outline";

const bucketName = process.env.REACT_APP_BUCKET_NAME;


// Función para detectar texto con AWS Rekognition
const detectText = async (imageKey, bucketName) => {
    const rekognitionClient = new AWS.Rekognition();
    const params = {
        Image: {
            S3Object: {
                Bucket: bucketName,
                Name: imageKey
            }
        }
    };

    try {
        const response = await rekognitionClient.detectText(params).promise();

        // Check if response.TextDetections is an array and not empty before proceeding
        if (Array.isArray(response.TextDetections) && response.TextDetections.length > 0) {
            // Filtrar TextDetections para incluir solo los elementos con Type "LINE"
            const lineDetections = response.TextDetections.filter(item => item.Type === "LINE");

            // Map lineDetections to extract desired information
            const textDetails = lineDetections.map((detection) => ({
                //DetectedText: formatearTextoSiEsFecha(detection.DetectedText),
                DetectedText: detection.DetectedText,
                Confidence: detection.Confidence,
                Id: detection.Id,
                ParentId: detection.ParentId,
                Type: detection.Type,
                Geometry: detection.Geometry,
            }));
            return textDetails;
        } else {
            console.log("No se encontraron detecciones de texto o no se detectaron líneas.");
            return []; // Return an empty array or handle the absence of detections as needed
        }
    } catch (error) {
        console.error("Error detecting text:", error);
        throw error;
    }
};


// Función para verificar el tipo de documento con Rekognition custom labels
const detectLabels = async (imageKey, bucketName, setLabels, projectVersionArn) => {
    const rekognitionCustom = new AWS.Rekognition();
    console.log('-------projectVersionArn: ', projectVersionArn);
    if (projectVersionArn) {
        const params = {
            Image: { S3Object: { Bucket: bucketName, Name: imageKey } },
            MaxResults: 1,
            MinConfidence: 80,
            ProjectVersionArn: projectVersionArn,
        };

        try {
            const responseLabels = await rekognitionCustom.detectCustomLabels(params).promise();
            console.log("Detected tags:", responseLabels.CustomLabels);

            //Datos de prueba
            /*const responseLabels = {
                "body": {
                    "CustomLabels": [
                        {
                            "Name": "DNI-Mexico",
                            "Confidence": 83.41299819946289
                        }
                    ]

                }
            };
            const datosJSON = responseLabels.body;
            setLabels(datosJSON.CustomLabels.map(label => label.Name));
            */

            setLabels(responseLabels.CustomLabels.map(label => label.Name));
        } catch (error) {
            console.error("Error al detectar etiquetas custom y texto:", error);
        }
    } else {
        console.error("Error al detectar etiquetas projectVersionArn null:", projectVersionArn);
    }
};

//formatea la fecha a dd-mm-yyyy
const formatDate = (date) => {
    // Obtener día, mes y año en el formato dd-mm-yyyy
    let day = date.getDate().toString().padStart(2, '0');
    let formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0'); // Sumar 1 al mes porque en JavaScript los meses van de 0 a 11
    let formattedYear = date.getFullYear();

    // Construir la cadena de fecha en el formato dd-mm-yyyy
    const formattedDate = `${day}-${formattedMonth}-${formattedYear}`;
    return formattedDate;
};

//-------------------formatearTexto con Bedrock-------------------------
/// Invocar el Modelo BEDROCK Anthropic Claude v2.1
async function formatearTexto(client, textDetails) {
    console.log('---->>>formatearTexto: ', textDetails);
    const textDetailsString = JSON.stringify(textDetails);

    // Construcción del prompt y envío del request a Bedrock
    const todayDate = new Date().toLocaleDateString('es-ES');

    const constructedModelPrompt = `Human: A continuación, se presentan los detalles extraídos de un documento de identificación. Los datos son los siguientes:\n\n${textDetailsString}\n\nOrganiza la información en formato JSON siguiendo el esquema: Tipo documento, País, Número de documento, Apellidos, Nombres, Fecha de nacimiento, Fecha de vencimiento. 

    - Si el número de documento extraído es de Venezuela y comienza con un número (ejemplo: '18.994.249'), agrégale la 'V' al principio (ejemplo: 'V 18.994.249') si el tipo de documento es 'Cédula de identidad' y el país es 'Venezuela'.
    - La fecha de vencimiento debe ser la más reciente entre todas las fechas presentes en los datos. Si el país es Colombia y el tipo de documento es 'Cédula de ciudadanía', no debe haber fecha de vencimiento.
    - Asegúrate de interpretar correctamente la fecha de nacimiento y considera que la fecha puede estar en diferentes formatos (ejemplo: '25-MAR-19', debe interpretarse como '25 de marzo de 2019').
    - Asegúrate que la fecha de Nacimiento solo es formatos fecha, no textos como nombres de ciudades.
    - Si la fecha de nacimiento no está explícitamente proporcionada en los datos, no la estimes y no calcules la edad. 
    - Incluye un campo de Status que indique si el documento está 'VENCIDO' o 'VIGENTE', basado en la fecha de vencimiento comparada con la fecha actual ${todayDate}.
    - Para Mexico el número de documento tiene un formato MEJY770119MHGNRS01
    - No unas lineas para formar nombres y apellidos, los apellidos y los nombres vienen en una linea en DetectedText.
    - Usa camel case para las claves en el JSON y proporciona la información en español.
    
    Si algún dato no está disponible, omítelo en la respuesta en lugar de dejarlo en blanco.\n\nAssistant:`;

    const modelId = 'anthropic.claude-3-5-sonnet-20240620-v1:0';
    const payload = {
        "anthropic_version": "bedrock-2023-05-31",
        "max_tokens": 1000,
        "messages": [
            {
                "role": "user",
                "content": constructedModelPrompt
            }
        ]
    };

    try {
        const data = await client.send(new InvokeModelCommand({
            body: JSON.stringify(payload),
            modelId,
            contentType: 'application/json',
            accept: 'application/json'
        }));

        const jsonString = new TextDecoder().decode(data.body);
        const response = JSON.parse(jsonString);

        const extractJson = (text) => {
            if (typeof text !== 'string') {
                console.error('Expected a string but got:', typeof text);
                return null;
            }

            // Usa una expresión regular para extraer el JSON entre las etiquetas de código
            const jsonMatch = text.match(/{\s*[^]*?\s*}/);
            return jsonMatch ? jsonMatch[0] : null;
        };

        // Obtén el texto del campo `content`
        const contentText = response.content?.[0]?.text;
        if (!contentText) {
            console.error('Content text is missing or undefined');
            return null;
        }

        // Extrae el JSON formateado
        const formattedJson = extractJson(contentText);

        if (formattedJson) {
            try {
                // Parsear el JSON
                const parsedJson = JSON.parse(formattedJson);
                console.log('Parsed JSON:', parsedJson);
                return parsedJson;
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
            }
        } else {
            console.error('No JSON found in the response');
            return null;
        }
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
}


//==================================function ValidarIdentidadV2()=======================================

function ValidarIdentidadV2() {

    const [locale, setLocale] = useState('es');  // idioma por defecto
    const { t } = useTranslation();
    const [projectVersionArn, setProjectVersionArn] = useState(null);
    const s3 = new AWS.S3();

    const [detectedText, setDetectedText] = useState([]);
    const [formatedText, setFormatedText] = useState(null);
    const [loadingFile, setLoadingFile] = useState(false);
    const [labels, setLabels] = useState([]);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(''); // Estado para almacenar la URL de la imagen cargada


    const toggleLanguage = () => {
        // Cambia el idioma entre inglés y español
        const newLocale = locale === 'en' ? 'es' : 'en';
        setLocale(newLocale);

        // Cambia el idioma en i18next
        i18n.changeLanguage(newLocale);
    };

    // borrar  resultados del modal
    const clearResults = () => {
        setIsVisible(false); // Oculta el modal o el componente que muestra los resultados
        setLabels([]); // Limpia el arreglo de etiquetas detectadas
        setUploadedImageUrl(''); // Limpia la URL de la imagen cargada
        setDetectedText([]);
        setFormatedText(null);
        setLabels([]);
    };

    // Initialize and configure BedrockRuntimeClient
    const client = new BedrockRuntimeClient({
        region: process.env.REACT_APP_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
        }
    });

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await fetch('/config.json');
                const data = await response.json();
                const projectVersion = data.projectVersion;
                setProjectVersionArn(projectVersion);
            } catch (error) {
                console.error('Error loading config:', error);
            }
        };

        fetchConfig();
    }, []);

    /////// Subir la imagen al s3
    function handleFileChange(event) {
        setLoadingFile(true);
        const file = event.target.files[0];
        if (!file) {
            console.error('No se seleccionó ningún archivo');
            setLoadingFile(false);
            return;
        }

        // Define imageKey aquí
        const imageKey = `IdImage-${Date.now()}.jpg`;

        // FileReader para leer el archivo seleccionado
        const reader = new FileReader();
        reader.onloadend = () => {
            setUploadedImageUrl(reader.result);
        };
        reader.readAsDataURL(file);

        const uploadParams = {
            Bucket: bucketName,
            Key: imageKey, // Usamos imageKey aquí
            Body: file,
            ContentType: file.type,
        };

        s3.upload(uploadParams, async function (err, data) {
            if (err) {
                console.error('Error al subir el archivo:', err);
            } else {
                console.log('Archivo subido correctamente:', data.Location);
            }

            // Ejecutar detectLabels y detectText en paralelo
            const [labelsResponse, textDetections] = await Promise.all([
                detectLabels(imageKey, bucketName, setLabels, projectVersionArn),
                detectText(imageKey, bucketName)
            ]);

            const textoFormateado = await formatearTexto(client, textDetections);
            setDetectedText(textDetections);
            setFormatedText(textoFormateado);
            setLoadingFile(false);
        });
    }


    /// Visibilidad de elementos
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const validateResponse = [
        {
            "account": "johndoe",
            "address": "José Ignacio",
            "did_you_mean": "johndoe@hotmail.com",
            "disposable": "false",
            "domain": "hotmai.com",
            "free_email": "false",
            "processed_at": "2023-09-03 15:10:08",
            "request_id": "NTUzNzQ4OQ==-6ab9a329-849b-4342-8475-df7610ee4647",
            "request_status": "completed",
            "role_based": "Acosta Manani",
            "status": "invalid",
            "sub_status": "possible_typo",
            "toxic": "false"
        }
    ];

    const tabledata = [
        {
          FirstName: "Rafael Jose",
          LastName: "Mendez Perez",
          Status: "valid",
          ID: "12345678",
          Date: "21/06/2024",
          Type: "🇵🇪 DNIe"
        },
        {
          FirstName: "Luisa María",
          LastName: "Rodríguez Mamani",
          Status: "expiring",
          ID: "12345678",
          Date: "11/04/2024",
          Type: "🇵🇪 DNI"
        },
        {
          FirstName: "Alejandra",
          LastName: "Paniagua Wong",
          Status: "valid",
          ID: "81163899",
          Date: "30/03/2027",
          Type: "🇪🇸 Passport"
        },
        {
          FirstName: "Jason Emmerson",
          LastName: "Lopez Vega",
          Status: "invalid",
          ID: "28928230",
          Date: "01/08/2023",
          Type: "🇵🇪 DNI"
        },
        {
          FirstName: "Miguel Angel",
          LastName: "Rodriguez Soto",
          Status: "valid",
          ID: "10307081",
          Date: "19/07/2025",
          Type: "🇪🇸 Passport"
        },
        {
          FirstName: "Manuela",
          LastName: "Mendez Perez",
          Status: "valid",
          ID: "67856003",
          Date: "07/06/2029",
          Type: "🇵🇪 DNIe"
        },
        {
          FirstName: "Diego",
          LastName: "Morales",
          Status: "valid",
          ID: "87738408",
          Date: "28/06/2025",
          Type: "🇵🇪 DNIe"
        },
        {
          FirstName: "Leonardo",
          LastName: "López",
          Status: "valid",
          ID: "30918606",
          Date: "27/02/2029",
          Type: "🇪🇸 Passport"
        },
        {
          FirstName: "Diego",
          LastName: "Jiménez",
          Status: "valid",
          ID: "46869408",
          Date: "06/11/2027",
          Type: "🇵🇪 DNIe"
        },
        {
          FirstName: "Gabriel",
          LastName: "Pérez",
          Status: "valid",
          ID: "30939824",
          Date: "29/07/2025",
          Type: "🇵🇪 DNI"
        },
        {
          FirstName: "Diego",
          LastName: "Herrera",
          Status: "expiring",
          ID: "47800800",
          Date: "01/02/2024",
          Type: "🇵🇪 DNIe"
        },
        {
          FirstName: "Luciana",
          LastName: "López",
          Status: "expiring",
          ID: "87839249",
          Date: "01/02/2024",
          Type: "🇵🇪 DNIe"
        }
    ];
    


    const statusAttributes = {
        'valid': { color: 'emerald', icon: CheckIcon },
        'expiring': { color: 'orange', icon: ShieldExclamationIcon },
        'invalid': { color: 'rose', icon: XIcon },
        'unknown': { color: 'gray', icon: QuestionMarkCircleIcon },
    };

    const getStatusAttribute = (status, attribute) => {
        return statusAttributes[status]?.[attribute] || statusAttributes['unknown'][attribute];
    };

    // Paginación

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(8);

    const nextPage = () => {
        if (currentPage < Math.ceil(tabledata.length / recordsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = tabledata.slice(indexOfFirstRecord, indexOfLastRecord);

    // fin Paginación 

    //resaltar popup con overlay oscuro
    const overlayStyles = {
        display: isVisible ? 'block' : 'none', // controla la visibilidad según la condición
        position: 'fixed', // posición fija
        top: '0',
        left: '0',
        width: '100vw', // ancho del viewport
        height: '100vh', // alto del viewport
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // color negro semitransparente
        zIndex: 1 // z-index bajo
    }
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <IntlProvider locale={locale}>
            <header className="bg-white shadow grid grid-cols-2 ">
                <div className="mx-10 max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
                    <h1 className="text-1xl font-bold tracking-tight text-gray-900">{t('national_document_recognition')} v2</h1>
                </div>
            </header>
            <main className="pl-12 pr-12 mt-6">

                <Card className='mt-10' style={{ backgroundColor: '#f9f9f9' }} >

                    <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >

                        <Flex>
                            <Card>
                                <Subtitle>{t('you_can')}</Subtitle>
                                <Flex justifyContent='start' alignItems='center'> <Icon variant="light" icon={PlusIcon} size="sm" color="indigo" />
                                    <Title className="ml-2"> {t('add_new_individual_identity')}</Title></Flex>
                                <Flex justifyContent='evenly' alignItems='center' className="gap-6 mt-6" >
                                    <TextInput icon={UploadIcon} placeholder={t('upload_new_file')} />
                                    <Button color='emerald' icon={PlusIcon} onClick={toggleVisibility}>{t('add')}</Button>
                                </Flex>
                            </Card>
                        </Flex> <Flex>
                            <Card>
                                <Subtitle>{t('you_can')}</Subtitle>
                                <Flex justifyContent='start' alignItems='center'> <Icon variant="light" icon={SearchIcon} size="sm" color="indigo" /> <Title className="ml-2"> {t('search_identities')}</Title></Flex>
                                <Flex justifyContent='evenly' alignItems='center' className="gap-10 mt-6" >
                                    <TextInput icon={SearchIcon} placeholder={t('id_number')} />
                                    <Button icon={SearchIcon} color="indigo">{t('search')}</Button>
                                </Flex>
                            </Card>
                        </Flex>
                    </Grid></Card>
                <Card style={{
                    display: isVisible ? 'block' : 'none',
                    position: 'fixed',
                    top: '100px',
                    left: '50%', // Centra el modal en la pantalla horizontalmente
                    transform: 'translateX(-50%)', // Ajuste para centrar correctamente
                    zIndex: "12",
                    width: "60%",
                    height: "auto"
                }}>
                    <Flex justifyContent='between' alignItems='center'>
                        <Title className="ml-2 font-bold">{t('add_identity_document')}</Title>
                        <Button icon={XIcon} size='sm' variant="light" onClick={clearResults}></Button>
                    </Flex>
                    <div className="flex mt-6">
                        {/* Utiliza flexbox para dividir en columnas */}
                        <div className="flex-1">
                            {/* Columna izquierda para la carga del archivo y la imagen */}
                            {
                                uploadedImageUrl ? (
                                    <img src={uploadedImageUrl} alt="Documento cargado" className="max-w-full h-auto" />
                                ) : (
                                    // Se muestra el input si no hay una imagen cargada aún
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/jpg, image/png"
                                        onChange={handleFileChange}
                                        className="mb-4" // Espacio extra para separar del título si lo deseas
                                    />
                                )
                            }
                        </div>
                        <div className="flex-1 ml-6">
                            {loadingFile && <img src="./spinner.gif" alt="loadingFile" style={{ width: '100%'}} />}

                            {formatedText && (
                                <>
                                    {labels.length > 0 && (
                                        <div>
                                            <h4 className='text-xl text-purple-900'>{t('national_document_detected')}</h4>
                                            <ul className='text-xl font-bold text-purple-900'>
                                                {labels.map((label, index) => (
                                                    <li key={index}>{label}</li>
                                                ))}
                                            </ul>
                                            <hr className='my-4 border-t-2 border-gray-300' />
                                        </div>
                                    )}
                                    <div>
                                        <div className='mt-4'>
                                            <div className='mb-1'>
                                                <span className='font-bold'>{t('document_type')}: </span>
                                                <span>
                                                    {formatedText[
                                                        Object.keys(formatedText).find(key => key.toLowerCase() === 'tipodocumento')
                                                    ] || t('not_available')}
                                                </span>
                                            </div>


                                            <div className='mb-1'>
                                                <span className='font-bold'>{t('country')}: </span>
                                                <span>
                                                    {formatedText[
                                                        Object.keys(formatedText).find(key => key.toLowerCase() === 'pais')
                                                    ] || t('not_available')}
                                                </span>
                                            </div>


                                            <div className='mb-1'>
                                                <span className='font-bold'>{t('id_number')}: </span>
                                                <span>
                                                    {formatedText[
                                                        Object.keys(formatedText).find(key => key.toLowerCase() === 'numerodocumento')
                                                    ] || t('not_available')}
                                                </span>
                                            </div>
                                            <div className='mb-1'>
                                                <span className='font-bold'>{t('expiration_date')}: </span>
                                                <span>
                                                    {formatedText[
                                                        Object.keys(formatedText).find(key => key.toLowerCase() === 'fechavencimiento')
                                                    ] || t('not_available')}
                                                </span>
                                            </div>
                                            <div className='mb-1'>
                                                <span className='font-bold'>{t('status')}: </span>
                                                <span className={
                                                    (formatedText[
                                                        Object.keys(formatedText).find(key => key.toLowerCase() === 'status')
                                                    ] === t('expired')) ? 'text-red-500 font-bold' :
                                                        (formatedText[
                                                            Object.keys(formatedText).find(key => key.toLowerCase() === 'status')
                                                        ] === t('valid')) ? 'text-green-500 font-bold' : ''
                                                }>
                                                    {formatedText[
                                                        Object.keys(formatedText).find(key => key.toLowerCase() === 'status')
                                                    ] || t('not_available')}
                                                </span>
                                            </div>
                                        </div>

                                        <hr className='my-4 border-t-2 border-gray-300' />

                                        <h4 className='text-xl mt-5 mb-2 font-medium text-purple-900'>{t('personal_data')}</h4>
                                        <pre className='text-gray-700'>
                                            {Object.keys(formatedText)
                                                .filter(key => !['tipodocumento', 'pais', 'fechavencimiento', 'status', 'numerodocumento'].includes(key.toLowerCase()))
                                                .filter(key => formatedText[key] !== null) // Filtra las claves con valores nulos
                                                .map(key => (
                                                    <div key={key} className='mb-1'>
                                                        <span className='font-bold'>{capitalizeFirstLetter(key)}: </span>
                                                        <span>
                                                            {typeof formatedText[key] === 'string' ? formatedText[key] : JSON.stringify(formatedText[key])}
                                                        </span>
                                                    </div>
                                                ))}
                                        </pre>

                                    </div>

                                </>

                            )}
                        </div>
                    </div>
                </Card>

                <Card className='mt-6'>
                    <Flex >
                        <Flex >
                            <Title>{t('national_document_recognition_history')}
                            </Title>
                        </Flex>
                        <Flex justifyContent='end'><DateRangePicker className="max-w-sm" enableSelect={true} />
                            <Button icon={SaveIcon} className='ml-4' size='sm' tooltip='Export' variant='secondary' iconPosition="left"></Button>
                        </Flex>
                    </Flex>

                    <div className="mt-6">
                        <Card>

                            <Table id="SingleValidationHistory" className="mt-5">
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell>{t('name')}</TableHeaderCell>
                                        <TableHeaderCell>{t('last_name')}</TableHeaderCell>
                                        <TableHeaderCell>{t('status')}</TableHeaderCell>
                                        <TableHeaderCell>ID</TableHeaderCell>
                                        <TableHeaderCell>{t('expiration_date')}</TableHeaderCell>
                                        <TableHeaderCell>{t('type')}</TableHeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentRecords.map((item) => (
                                        <TableRow key={item.Nombre}>
                                            <TableCell>
                                                <Flex justifyContent='start'><Text >{item.FirstName} </Text></Flex>
                                            </TableCell>

                                            <TableCell>
                                                <Text>{item.LastName}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Badge color={getStatusAttribute(item.Status, 'color')}>
                                                    <Text>{item.Status} </Text>
                                                </Badge>
                                            </TableCell>
                                            <TableCell>
                                                <Text>{item.ID}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text>{item.Date}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text>{item.Type}</Text>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFoot>
                                    <TableFooterCell colSpan={5}>
                                        <Button size="xs" variant="secondary" onClick={prevPage} icon={ChevronLeftIcon}></Button>
                                        <span className='mx-4' >{` Page ${currentPage} `}</span>
                                        <Button size="xs" variant="secondary" onClick={nextPage} icon={ChevronRightIcon} ></Button>
                                    </TableFooterCell>
                                </TableFoot>

                            </Table>

                        </Card>

                    </div>
                </Card>



            </main>
        </IntlProvider>
    );
}

export default ValidarIdentidadV2;
