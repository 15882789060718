import { AWS } from './Login.js';
import React, { useState, useEffect } from 'react';
import { IntlProvider, FormattedNumber, FormattedDate } from 'react-intl';
import { useTranslation } from 'react-i18next';
import i18n from "./i18n.js"; //  archivo de configuración de lenguaje
import { en } from "date-fns/locale";
import {
  SearchSelect, SearchSelectItem,
  DatePicker, DatePickerValue, DateRangePicker,
  DonutChart,
  TextInput,
  Legend,
  List,
  Grid, Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels, Divider, Badge, CategoryBar, ProgressBar, Metric, Card, Title, Subtitle, Text, Flex, Button, Icon, Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell, TableFoot, TableFooterCell
} from "@tremor/react";
import { ArrowNarrowRightIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, SearchIcon, StatusOnlineIcon, BackspaceIcon, CashIcon, DuplicateIcon, MailIcon, StarIcon, TrashIcon, UserIcon, ShieldExclamationIcon, XIcon, QuestionMarkCircleIcon, ThumbDownIcon, ThumbUpIcon, DocumentSearchIcon, CheckCircleIcon, TagIcon, ClockIcon, MenuIcon, CloudIcon, UploadIcon, PlusIcon, ChipIcon, CalculatorIcon, ShareIcon, SaveIcon, PhoneIcon, UserAddIcon } from "@heroicons/react/outline";
import { DropZone, VisuallyHidden } from '@aws-amplify/ui-react';
import { Tooltip } from 'antd';

const bucketName = process.env.REACT_APP_BUCKET_NAME;



// Función para detectar texto con AWS Rekognition
const detectText = async (imageKey, bucketName) => {
  const rekognitionClient = new AWS.Rekognition();
  const params = {
    Image: {
      S3Object: {
        Bucket: bucketName,
        Name: imageKey
      }
    }
  };

  try {
    const response = await rekognitionClient.detectText(params).promise();
    console.log("Texto detectado:", response.TextDetections);
    // Procesar las detecciones de texto para incluir la información deseada
    const textDetails = response.TextDetections.map((detection) => ({
      DetectedText: detection.DetectedText,
      Confidence: detection.Confidence,
      Id: detection.Id,
      ParentId: detection.ParentId,
      Type: detection.Type,
    }));

    console.log('Detalles del texto detectado:', textDetails);
    // Aquí puedes hacer algo con textDetails, como actualizar el estado o mostrar en la UI

    return response.TextDetections.map(text => ({ DetectedText: text.DetectedText, Confidence: text.Confidence }));
  } catch (error) {
    console.error("Error al detectar texto:", error);
    throw error;
  }
};

// Función para verificar el tipo de documento con Rekognition custom labels
const detectLabels = async (imageKey, bucketName, setLabels, setDetectedText) => {
  const rekognitionCustom = new AWS.Rekognition();
  const params = {
    Image: { S3Object: { Bucket: bucketName, Name: imageKey } },
    MaxResults: 1,
    MinConfidence: 80,
    ProjectVersionArn: 'arn:aws:rekognition:us-east-1:905418296062:project/document_id_test/version/document_id_test.2024-02-21T08.10.41/1708521041421'
  };

  try {
    const responseLabels = await rekognitionCustom.detectCustomLabels(params).promise();
    console.log("Etiquetas detectadas:", responseLabels.CustomLabels);
    setLabels(responseLabels.CustomLabels.map(label => label.Name));

    // Si se detectan etiquetas personalizadas, proceder a detectar texto
    if (responseLabels.CustomLabels.length > 0) {
      const textDetections = await detectText(imageKey, bucketName);
      setDetectedText(textDetections);
    }
  } catch (error) {
    console.error("Error al detectar etiquetas custom y texto:", error);
  }
};


function App({ onSelectOption }) {

  const [locale, setLocale] = useState('es');  // idioma por defecto
  const { t } = useTranslation();


  const handleEnviar = () => {
    onSelectOption(1); // Llama a la función handleSelectOption de App para ir a VideoSignature
  };



  const toggleLanguage = () => {
    // Cambia el idioma entre inglés y español
    const newLocale = locale === 'en' ? 'es' : 'en';
    setLocale(newLocale);

    // Cambia el idioma en i18next
    i18n.changeLanguage(newLocale);
  };

  // borrar  resultados del modal
  const clearResults = () => {
    setIsVisible(false); // Oculta el modal o el componente que muestra los resultados
    setLabels([]); // Limpia el arreglo de etiquetas detectadas
    setUploadedImageUrl(''); // Limpia la URL de la imagen cargada
  };

  /////// constantes

  const s3 = new AWS.S3();
  const reader = new FileReader();

  const [detectedText, setDetectedText] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [labels, setLabels] = useState([]);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(''); // Estado para almacenar la URL de la imagen cargada


  const [expirationDate, setExpirationDate] = useState("");

  useEffect(() => {
    // Actualizamos el regex para que coincida con el formato yyyy-mm-dd
    const dateRegex = /(\d{4})-(\d{2})-(\d{2})/g;
    let latestDate = new Date(0); // Inicializa a una fecha antigua
    detectedText.forEach(item => {
      const matches = item.DetectedText.match(dateRegex);
      if (matches) {
        matches.forEach(match => {
          const dateParts = match.split("-");
          // Asegúrate de que las partes de la fecha se interpreten en el orden correcto para el formato yyyy-mm-dd
          const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
          if (date > latestDate) {
            latestDate = date;
          }
        });
      }
    });

    // Si encontramos una fecha válida, actualiza el estado
    if (latestDate > new Date(0)) {
      // Actualiza el formato de fecha según necesites para mostrarlo en la interfaz
      setExpirationDate(latestDate.toISOString().split('T')[0]);
    }
  }, [detectedText]);

  // Ejemplo de función donde se llama a detectLabels
  const handleDetectLabelsAndText = async (imageKey) => {
    try {
      // Asegúrate de pasar setDetectedText aquí correctamente
      await detectLabels(imageKey, bucketName, setLabels, setDetectedText);
    } catch (error) {
      console.error("Error en handleDetectLabelsAndText:", error);
    }
  };

  /////// Subir la imagen al s3

  function handleFileChange(event) {
    setLoadingFile(true);
    const file = event.target.files[0];
    if (!file) {
      console.error('No se seleccionó ningún archivo');
      setLoadingFile(false);
      return;
    }

    // Define imageKey aquí
    const imageKey = `IdImage-${Date.now()}.jpg`;

    // FileReader para leer el archivo seleccionado
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImageUrl(reader.result);
      console.log('URL de la imagen', reader.result); // Establece la URL de la imagen para mostrarla
      setLoadingFile(false);
    };
    reader.readAsDataURL(file);

    const uploadParams = {
      Bucket: bucketName,
      Key: imageKey, // Usamos imageKey aquí
      Body: file,
      ContentType: file.type,
    };

    s3.upload(uploadParams, async function (err, data) {
      setLoadingFile(false); // Esta línea es común a ambos casos, así que se puede sacar del if-else
      if (err) {
        console.error('Error al subir el archivo:', err);
      } else {
        console.log('Archivo subido correctamente:', data.Location);
      }

      // Llamar a detectLabels independientemente de si hay error o no
      const labelsResponse = await detectLabels(imageKey, bucketName, setLabels, setDetectedText);
      console.log('Respuesta de detectLabels:', labelsResponse);
    });
  }


  /// Visibilidad de elementos
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const validateResponse = [
    {
      "account": "johndoe",
      "address": "José Ignacio",
      "did_you_mean": "johndoe@hotmail.com",
      "disposable": "false",
      "domain": "hotmai.com",
      "free_email": "false",
      "processed_at": "2023-09-03 15:10:08",
      "request_id": "NTUzNzQ4OQ==-6ab9a329-849b-4342-8475-df7610ee4647",
      "request_status": "completed",
      "role_based": "Acosta Manani",
      "status": "invalid",
      "sub_status": "possible_typo",
      "toxic": "false"
    }
  ];

  const tabledata = [
    { Nombre: "Diego", Apellido: "Morales", Confianza: "99.01%", Coincidencia: "98.98%", ID: "87738408", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-001" },
    { Nombre: "Leonardo", Apellido: "López", Confianza: "99.67%", Coincidencia: "62.65%", ID: "30918606", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-002" },
    { Nombre: "Diego", Apellido: "Jiménez", Confianza: "92.03%", Coincidencia: "93.12%", ID: "46869408", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-003" },
    { Nombre: "Gabriel", Apellido: "Pérez", Confianza: "97.76%", Coincidencia: "99.96%", ID: "30939824", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-004" },
    { Nombre: "Diego", Apellido: "Herrera", Confianza: "91.56%", Coincidencia: "90.45%", ID: "47800800", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-005" },
    { Nombre: "Luciana", Apellido: "López", Confianza: "34.45%", Coincidencia: "98.90%", ID: "87839249", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-006" },
    { Nombre: "Diego", Apellido: "Morales", Confianza: "99.01%", Coincidencia: "98.98%", ID: "87738408", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-001" },
    { Nombre: "Anais", Apellido: "Neira", Confianza: "99.67%", Coincidencia: "99.65%", ID: "30918606", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-002" },
    { Nombre: "Diego", Apellido: "Jiménez", Confianza: "92.03%", Coincidencia: "93.12%", ID: "46869408", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-003" },
    { Nombre: "Gabriel", Apellido: "Pérez", Confianza: "97.76%", Coincidencia: "99.96%", ID: "30939824", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-004" },
    { Nombre: "Diego", Apellido: "Herrera", Confianza: "91.56%", Coincidencia: "90.45%", ID: "47800800", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-005" },
    { Nombre: "Luciana", Apellido: "López", Confianza: "34.45%", Coincidencia: "98.90%", ID: "87839249", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-006" },
    { Nombre: "Diego", Apellido: "Morales", Confianza: "99.01%", Coincidencia: "98.98%", ID: "87738408", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-001" },
    { Nombre: "Leonardo", Apellido: "López", Confianza: "99.67%", Coincidencia: "62.65%", ID: "30918606", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-002" },
    { Nombre: "Diego", Apellido: "Jiménez", Confianza: "92.03%", Coincidencia: "93.12%", ID: "46869408", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-003" },
    { Nombre: "Gabriel", Apellido: "Pérez", Confianza: "97.76%", Coincidencia: "99.96%", ID: "30939824", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-004" },
    { Nombre: "Diego", Apellido: "Herrera", Confianza: "91.56%", Coincidencia: "90.45%", ID: "47800800", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-005" },
    { Nombre: "Luciana", Apellido: "López", Confianza: "34.45%", Coincidencia: "98.90%", ID: "87839249", Fecha: "04/04/2024", Sesion: "7a65df39-2ca4-41a6-006" },
  ];




  // Paginación

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);

  const nextPage = () => {
    if (currentPage < Math.ceil(tabledata.length / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tabledata.slice(indexOfFirstRecord, indexOfLastRecord);

  // fin Paginación 


  const determineColor = (value) => parseFloat(value) > 90 ? 'emerald' : 'rose';


  //resaltar popup con overlay oscuro
  const overlayStyles = {
    display: isVisible ? 'block' : 'none', // controla la visibilidad según la condición
    position: 'fixed', // posición fija
    top: '0',
    left: '0',
    width: '100vw', // ancho del viewport
    height: '100vh', // alto del viewport
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // color negro semitransparente
    zIndex: 1 // z-index bajo
  }





  return (
    <IntlProvider locale={locale}>
      <header className="bg-white shadow grid grid-cols-2 ">
        <div className="mx-10 max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
          <h1 className="text-1xl font-bold tracking-tight text-gray-900">{t('videosign_history')}</h1>
        </div>
      </header>
      <main className="pl-12 pr-12 mt-6">

        <Card className='mt-10' style={{ backgroundColor: '#f9f9f9' }} >

          <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >



            <Flex>
              <Card>
                <Subtitle>{t('you_can')}</Subtitle>
                <Flex justifyContent='start' alignItems='center'> <Icon variant="light" icon={PlusIcon} size="sm" color="indigo" />
                  <Title className="ml-2"> {t('add_new_individual_identity')}</Title></Flex>
                <Flex justifyContent='evenly' alignItems='center' className="gap-6 mt-6" >
                  <TextInput icon={UserAddIcon} placeholder={t('add_email_or_phone_number')} />
                  <Button color='emerald' icon={PlusIcon} onClick={handleEnviar}>{t('send')}</Button>
                </Flex>
              </Card>
            </Flex><Flex>
              <Card>
                <Subtitle>{t('you_can')}</Subtitle>
                <Flex justifyContent='start' alignItems='center'> <Icon variant="light" icon={SearchIcon} size="sm" color="indigo" /> <Title className="ml-2"> {t('search_identities')}</Title></Flex>
                <Flex justifyContent='evenly' alignItems='center' className="gap-10 mt-6" >
                  <TextInput icon={SearchIcon} placeholder={t('id_number')} />
                  <Button icon={SearchIcon} color="indigo">{t('search')}</Button>
                </Flex>
              </Card>
            </Flex>
          </Grid></Card>
        <Card style={{
          display: isVisible ? 'block' : 'none',
          position: 'fixed',
          top: '100px',
          left: '50%', // Centra el modal en la pantalla horizontalmente
          transform: 'translateX(-50%)', // Ajuste para centrar correctamente
          zIndex: "12",
          width: "70%",
          height: "70%"
        }}>
          <Flex justifyContent='between' alignItems='center'>
            <Title className="ml-2">Agrega un documento de identidad</Title>
            <Button icon={XIcon} size='sm' variant="light" onClick={clearResults}></Button>
          </Flex>
          <div className="flex mt-6">
            {/* Utiliza flexbox para dividir en columnas */}
            <div className="flex-1">
              {/* Columna izquierda para la carga del archivo y la imagen */}
              {
                uploadedImageUrl ? (
                  <img src={uploadedImageUrl} alt="Documento cargado" className="max-w-full h-auto" />
                ) : (
                  // Se muestra el input si no hay una imagen cargada aún
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg, image/png"
                    onChange={handleFileChange}
                    className="mb-4" // Espacio extra para separar del título si lo deseas
                  />
                )
              }
            </div>
            <div className="flex-1 ml-5">
              {/* Columna derecha para los labels detectados */}
              {labels.length > 0 && (
                <div>
                  <h4 className='font-bold text-purple-900'>Documentos detectados:</h4>
                  <ul>
                    {labels.map((label, index) => (
                      <li key={index}>{label}</li>
                    ))}
                  </ul><h2>{t('expiration_date')}: {expirationDate}</h2>
                </div>
              )}
            </div>
          </div>
        </Card>

        <Card className='mt-6'>
          <Flex >
            <Flex ><Title>{t('biometric_video_liveness_history')}</Title></Flex>
            <Flex justifyContent='end'><DateRangePicker className="max-w-sm" enableSelect={true} />
              <Button icon={SaveIcon} className='ml-4' size='sm' tooltip='Exportar' variant='secondary' iconPosition="left"></Button>
            </Flex></Flex>



          <div className="mt-6">
            <Card>

              <Table id="SingleValidationHistory" className="mt-5">
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>{t('name')}</TableHeaderCell>
                    <TableHeaderCell>{t('last_name')}</TableHeaderCell>

                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>{t('live_session_id')}</TableHeaderCell>
                    <TableHeaderCell>{t('modification_date')} </TableHeaderCell>
                    <TableHeaderCell>{t('coincidence')} </TableHeaderCell>
                    <TableHeaderCell>{t('liveness_confidence')}</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentRecords.map((item) => (
                    <TableRow key={item.Nombre}>
                      <TableCell>
                        <Flex justifyContent='start'><Text >{item.Nombre} </Text></Flex>
                      </TableCell>

                      <TableCell>
                        <Text>{item.Apellido}</Text>
                      </TableCell>

                      <TableCell>
                        <Text>{item.ID}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>{item.Sesion}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>{item.Fecha}</Text>
                      </TableCell>
                      <TableCell>
                        <Badge color={determineColor(item.Coincidencia)}>
                          <Text>{item.Coincidencia}</Text>
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <Badge color={determineColor(item.Confianza)}>
                          <Text>{item.Confianza}</Text>
                        </Badge>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFoot>
                  <TableFooterCell colSpan={5}>
                    <Button size="xs" variant="secondary" onClick={prevPage} icon={ChevronLeftIcon}></Button>
                    <span className='mx-4' >{t('page')} {`${currentPage} `}</span>
                    <Button size="xs" variant="secondary" onClick={nextPage} icon={ChevronRightIcon} ></Button>
                  </TableFooterCell>
                </TableFoot>

              </Table>

            </Card>

          </div>
        </Card>



      </main>
    </IntlProvider>
  );
}

export default App;
