import React, { useState } from 'react';
import { IntlProvider, FormattedNumber, FormattedDate } from 'react-intl';

import { useTranslation } from 'react-i18next';
import i18n from "./i18n.js"; //  archivo de configuración de lenguaje

import {
    SearchSelect, SearchSelectItem,
    DatePicker, DatePickerValue, DateRangePicker,
    DonutChart,
    TextInput,
    Legend,
    BarList,
    List,
    ListItem,
    Grid, Tab,
    LineChart,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels, Divider, Badge, CategoryBar, ProgressBar, Metric, Card, Title, Subtitle, Text, Flex, Button, Icon, Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell, TableFoot, TableFooterCell
  } from "@tremor/react";
  import { ArrowNarrowRightIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, SearchIcon, StatusOnlineIcon, BackspaceIcon, CashIcon, DuplicateIcon, MailIcon, StarIcon, TrashIcon, UserIcon, ShieldExclamationIcon, XIcon, QuestionMarkCircleIcon, ThumbDownIcon, ThumbUpIcon, DocumentSearchIcon, CheckCircleIcon, TagIcon, ClockIcon, MenuIcon, CloudIcon, UploadIcon, PlusIcon, ChipIcon, CalculatorIcon, ShareIcon, SaveIcon, CogIcon, DeviceMobileIcon } from "@heroicons/react/outline";
import { RiVideoAddLine, RiWebhookLine } from '@remixicon/react';
import { FaVideo, FaVideoSlash, FaWhatsapp } from 'react-icons/fa';


const Home = () => {

    const [locale, setLocale] = useState('en');  // idioma por defecto
    const { t } = useTranslation();
    const toggleLanguage = () => {
      // Cambia el idioma entre inglés y español
      const newLocale = locale === 'en' ? 'es' : 'en';
      setLocale(newLocale);
  
      // Cambia el idioma en i18next
      i18n.changeLanguage(newLocale);
    };
  
   ///////
   
   
  
  
   /// Visibilidad de elementos
    const [isVisible, setIsVisible] = useState(false);
  
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };
  
  
  
  /////////////---------START dummy data
  
    const tabledata = [
      { Nombre: "VENTAS Maduración de leads", Estado: "Active", ID: "ID 87738", Fecha: "28/02/2024", Tipo: "📊 " },
      { Nombre: "Microservice Orquestation", Estado: "New", ID: "ID 30918", Fecha: "27/02/2024", Tipo: "📊 " },
      { Nombre: "Encuesta tipo formulario",  Estado: "Active", ID: "ID 46869", Fecha: "06/11/2023", Tipo: "📊" },
      { Nombre: "Envío de Facturación", Estado: "Active", ID: "ID 30939", Fecha: "29/07/2023", Tipo: "📊" },
     
    ];
    const tabledata2 = [
      { Nombre: "Lista Master de Clientes", Estado: "Active", ID: "ID 87738", Fecha: "22/02/2024", Tipo: "📊 " },
      { Nombre: "Microservice Orquestation", Estado: "New", ID: "ID 30918", Fecha: "27/02/2023", Tipo: "📊 " },
      { Nombre: "Encuesta tipo formulario",  Estado: "Active", ID: "ID 46869", Fecha: "06/11/2023", Tipo: "📊" },
      { Nombre: "VENTAS Maduración de leads", Estado: "Active", ID: "ID 30939", Fecha: "29/07/2023", Tipo: "📊" },
     
    ];
  
    const statusAttributes = {
      'Active': { color: 'emerald', icon: CheckIcon },
      'por vencer': { color: 'orange', icon: ShieldExclamationIcon },
      'inválido': { color: 'rose', icon: XIcon },
      'New': { color: 'gray', icon: QuestionMarkCircleIcon },
    };
  
    const getStatusAttribute = (status, attribute) => {
      return statusAttributes[status]?.[attribute] || statusAttributes['unknown'][attribute];
    };
  
    const data = [
      {
        name: 'Email',
        value: 109989,
        href: '#',
        icon: function EmailIcon() {
          return (
           <Icon  icon={MailIcon} size="sm" className="mr-2.5 " />
          );
        },
      },
      {
        name: 'SMS',
        value: 98378,
        href: '#',
        icon: function SMSIcon() {
          return (
            <Icon  icon={DeviceMobileIcon} size="sm" className="mr-2.5 " />
           );
        },
      },
      {
        name: 'API+',
        value: 89757,
        href: '#',
        icon: function ApiIcon() {
          return (
            <Icon  icon={RiWebhookLine} size="sm" className="mr-2.5 " />
           );
        },
      },
      {
        name: 'WhatsApp',
        value: 34903,
        href: '#',
        icon: function WabaIcon() {
          return (
            <Icon  icon={FaWhatsapp} size="sm" color="blue" className="mr-2.5 " />
           );
        },
      },
      {
        name: 'Videos',
        value: 13949,
        href: 'https://www.youtube.com/@tremorlabs3079',
        icon: function YouTubeIcon() {
          return (
            <Icon  icon={RiVideoAddLine} size="sm" className="mr-2.5 " />
           );
        },
      },
    ];
    
  
    const chartdata = [
      {
        date: 'Jan 23',
        'Email': 167098,
        'SMS': 145765,
        'Call': 13524,
        'API+': 115444,
        'API': 150444,
      },
      {
        date: 'Feb 23',
        'Email': 129763,
        'SMS': 110643,
        'Call': 15567,
        'API+': 85854,
        'API': 180333,
      },
      {
        date: 'Mar 23',
        'Email': 156424,
        'SMS': 149424,
        'Call': 14333,
        'API+': 90333,
        'API': 130333,
      },
      {
        date: 'Apr 23',
        'Email': 165333,
        'SMS': 112324,
        'Call': 12545,
        'API+': 105333,
        'API': 170333,
      },
      {
        date: 'May 23',
        'Email': 153321,
        'SMS': 138212,
        'Call': 16534,
        'API+': 100343,
        'API': 110334,
      },
      {
        date: 'Jun 23',
        'Email': 124212,
        'SMS': 145357,
        'Call': 17543,
        'API+': 75533,
        'API': 140553,
      },
    ];
    
  /////////////---------END dummy data
  
  
    // Paginación
  
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(8);
  
    const nextPage = () => {
      if (currentPage < Math.ceil(tabledata.length / recordsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    }
  
    const prevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    }
  
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = tabledata.slice(indexOfFirstRecord, indexOfLastRecord);
    const currentRecords2 = tabledata2.slice(indexOfFirstRecord, indexOfLastRecord);
  
    // fin Paginación 
  
   
    //resaltar popup con overlay oscuro
    const overlayStyles = {
      display: isVisible ? 'block' : 'none', // controla la visibilidad según la condición
      position: 'fixed', // posición fija
      top: '0',
      left: '0',
      width: '100vw', // ancho del viewport
      height: '100vh', // alto del viewport
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // color negro semitransparente
      zIndex: 1 // z-index bajo
  }
  





  return (
    <div>
      <IntlProvider locale={locale}>
    <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >
      <Flex className="mx-10 max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <h1 className="text-xl font-bold tracking-tight text-gray-900"> {t('welcome')}</h1>
      </Flex>
      <Flex justifyContent='end' className="mx-10 max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <Grid numItemsMd={2} numItemsLg={2} className="gap-7 mt-0" >
      <Flex justifyContent='end'> <p className="text-sm font-bold tracking-tight text-gray-900 ">{t('user')}</p></Flex>
      <Flex ><Icon variant="light" icon={CogIcon} size="sm" className='mr-20' /></Flex> </Grid>
      </Flex>
    </Grid>
        <main className="pl-12 pr-12 mt-0">

          <Card className='mt-6' style={{ backgroundColor: '#f9f9f9' }} >

            <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >
              <Flex>
                <Card>
                  <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >

                    <Flex justifyContent='start' alignItems='center'>
                      <Icon variant="light" icon={ClockIcon} size="sm" />
                      <Title className="ml-2"> {t('recent_conversations')}</Title>
                    </Flex>

                    {/* <Flex justifyContent='end' alignItems='center' className="gap-6 " >
                      <Button color='emerald' icon={PlusIcon} onClick={toggleVisibility}>Nueva</Button>
                    </Flex> */}

                  </Grid>   <Table id="SingleValidationHistory" className="mt-5">
                   
                      <TableBody>
                        {currentRecords.map((item) => (
                          <TableRow key={item.Nombre}>
                            <TableCell>
                              <Flex justifyContent='start'><Text >{item.Nombre} </Text></Flex>
                            </TableCell>
                            
                            
                            <TableCell>
                              <Badge color={getStatusAttribute(item.Estado, 'color')}>
                                <Text>{item.Estado} </Text>
                              </Badge>
                            </TableCell>
                            <TableCell>
                              <Text>{item.ID}</Text>
                            </TableCell>
                            <TableCell>
                              <Text>{item.Fecha}</Text>
                            </TableCell>
                            <TableCell>
                              <Text>{item.Tipo}</Text>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFoot>
                        <TableFooterCell colSpan={5}>
                          <Button size="xs" variant="secondary" iconPosition="right" onClick={nextPage} icon={ChevronRightIcon}> {t('go_to_conversations')}</Button>
                        </TableFooterCell>
                      </TableFoot>

                    </Table>
                </Card>
              </Flex>

              <Flex>
              <Card>
                  <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >

                    <Flex justifyContent='start' alignItems='center'>
                      <Icon variant="light" icon={ClockIcon} size="sm" />
                      <Title className="ml-2"> {t('contact_list')} </Title>
                    </Flex>

                    {/* <Flex justifyContent='end' alignItems='center' className="gap-6 " >
                      <Button color='emerald' icon={PlusIcon} onClick={toggleVisibility}>Nueva</Button>
                    </Flex> */}

                  </Grid>   <Table id="SingleValidationHistory" className="mt-5">
                   
                      <TableBody>
                        {currentRecords2.map((item) => (
                          <TableRow key={item.Nombre}>
                            <TableCell>
                              <Flex justifyContent='start'><Text >{item.Nombre} </Text></Flex>
                            </TableCell>
                            
                            
                            <TableCell>
                              
                            </TableCell>
                            <TableCell>
                              <Text>{item.ID}</Text>
                            </TableCell>
                            <TableCell>
                              <Text>{item.Fecha}</Text>
                            </TableCell>
                            
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFoot>
                        <TableFooterCell colSpan={5}>
                          <Button size="xs" variant="secondary" iconPosition="right" onClick={nextPage} icon={ChevronRightIcon}>{t('go_to_contacts')}</Button>
                        </TableFooterCell>
                      </TableFoot>

                    </Table>
                </Card>
                  </Flex>
                </Grid></Card>
                <Card style={{
        display: isVisible ? 'block' : 'none',
        position: 'fixed',
        top: '100px',  // Puedes ajustar esto según lo necesites
        left: '200px', // Puedes ajustar esto según lo necesites
        zIndex: "12",
        width: "70%",
        height:"70%"
      }}>
        <Flex justifyContent='between' alignItems='center'>
          <Title className="ml-2"> {t('new_conversation')}</Title><Button icon={XIcon} size='sm' variant="light" onClick={toggleVisibility}></Button></Flex>
        <Flex className="mt-6">
          <SearchSelect>
            <SearchSelectItem value="1" icon={MenuIcon}>
              Campo 1 - Nombre
            </SearchSelectItem>
            <SearchSelectItem value="2" icon={MenuIcon}>
            Campo 2 - Apellido
            </SearchSelectItem>
            <SearchSelectItem value="3" icon={MenuIcon}>
            Campo 3 - Email
            </SearchSelectItem>
            <SearchSelectItem value="4" icon={MenuIcon}>
            Campo 4 - Cargo
            </SearchSelectItem>
          </SearchSelect>
          <Button className="ml-6">{t('select_type')}</Button>
        </Flex>
      </Card>

          <Grid numItemsMd={2} numItemsLg={2} className="gap-10 mt-0" >


            <Card className='mt-6'>
              <h3 className="text-tremor-title text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">{t('monthly_history')}</h3>
              <p className="mt-4 text-tremor-default flex items-center justify-between text-tremor-content dark:text-dark-tremor-content">
                <span>{t('channel')}</span>
                <span>{t('total')}</span>
              </p>
              <BarList data={data} className="mt-2" />
            </Card>

            <Card className='mt-6'>
              <Flex>
                <Title>{t('annual_history')}</Title>
              </Flex>
              <Flex justifyContent='end'><DateRangePicker className="max-w-sm" enableSelect={true} />
                {/* <Button icon={SaveIcon} className='ml-4' size='sm' tooltip='Exportar' variant='secondary' iconPosition="left"></Button> */}
              </Flex> <LineChart
        className="h-72"
        data={chartdata}
        index="date"
        categories={[
          'Email',
          'SMS',
          'Call',
          'API+',
          'API'
        ]}
        colors={['orange-600', 'fuchsia-600', 'indigo-600', 'rose-600', 'green-600'  ]}
        yAxisWidth={30}
      />

            </Card>


      </Grid>
          
      </main>
    </IntlProvider>

    </div>
  );
};

export default Home;