// Login.js
import React, { useState} from 'react';
import { Card, Grid, Flex } from '@tremor/react';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';


//--------------------------------------------
//------------------Constantes----------------
//--------------------------------------------
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
const region = process.env.REACT_APP_REGION;
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;


export { AWS };

// Para autenticar usuario 
const poolData = {
    UserPoolId: userPoolId, 
    ClientId: clientId 
};
const userPool = new CognitoUserPool(poolData);

const signIn = (username, password) => {
    const authenticationData = {
        Username: username,
        Password: password
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);
    
    const userData = {
        Username: username,
        Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                console.log('Autenticación exitosa:');

                // Configura las credenciales de AWS
                AWS.config.region = region; // Reemplaza con tu región de AWS
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: identityPoolId, // Reemplaza con el ID de tu Identity Pool de Cognito
                    Logins: {
                        [`cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`]: result.getIdToken().getJwtToken()
                    }
                });

                // Refresca las credenciales de AWS
                AWS.config.credentials.refresh((error) => {
                    if (error) {
                        console.error('Error al refrescar las credenciales de AWS:', error);
                        reject(error);
                    } else {
                        console.log('Credenciales de AWS actualizadas correctamente');
                        resolve();
                    }
                });
            },
            onFailure: (error) => {
                console.error('Error en la autenticación:', error);
                reject(error);
            }
        });
    });
};


const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
//------------------------------------------------  
//------------------handleLogin-------------------
//------------------------------------------------
  const handleLogin = async() => {
    console.log("----------handleLogin----------")
    // Autenticamos con cognito
    try {
        await signIn(username, password);
        console.log('Usuario autenticado correctamente');
        onLogin();
    } catch (error) {
        console.error('Error de autenticación:', error);
        alert('Login failed. Please try again.');
    }
  };


return (
    <>
       <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto w-full">
        {/* Tarjeta con sombra */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="lg:flex">
            
            {/* Columna de Bienvenida */}
            <div className="lg:flex lg:flex-1">
              <div className="p-8 mt-10">
                <img src="logo.png" className="w-64 mb-8" alt="DANAconnect" />
                <h2 className="text-xl font-extrabold text-gray-600 mb-2">Bienvenido a DANAconnect</h2>
                <p className="text-gray-600">La única plataforma de comunicación con cliente que una empresa financiera necesita.</p>
              </div>
            </div>
            
            {/* Columna del Formulario de Login */}
            <div className="lg:flex lg:flex-1 lg:justify-center lg:items-center">
              <div className="p-8">
              <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Usuario</label>
                   
                   <input id="email" name="email" value={username} onChange={(e) => setUsername(e.target.value)} autoComplete="email" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                 
                  </div>

                  <div>
                    <div className="flex items-center justify-between">
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900" >Clave</label>
                      <div className="text-sm">
                        <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">¿Olvidaste tu clave?</a>
                      </div>
                    </div>
                    <div className="mt-2">
                        
                      <input id="password" name="password" type="password" autoComplete="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                  </div>


                  <div>
                    <button id="submit" onClick={handleLogin} className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Entrar
                    </button>
                  </div>
                </form>
                <p className="mt-6 text-center text-sm text-gray-500">
                Entrar con una
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500"> compañía diferente </a>
           </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Login;
