import React, { useState, useEffect } from 'react';
import { Card, Title, Divider, Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell, Button, Textarea, TextInput } from "@tremor/react";
import { FaEye, FaTimes, FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';
import { S3Client, ListObjectsV2Command, PutObjectCommand } from '@aws-sdk/client-s3';
import { TextractClient, StartDocumentTextDetectionCommand, GetDocumentTextDetectionCommand } from '@aws-sdk/client-textract';
import { BedrockRuntimeClient, InvokeModelCommand } from '@aws-sdk/client-bedrock-runtime';

import i18n from "./i18n.js"; //  archivo de configuración de lenguaje
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const ProcesamientoInteligente = () => {
  const [locale, setLocale] = useState('es');  // idioma por defecto
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const [loadingFiles, setLoadingFiles] = useState({});
  const [analyzedText, setAnalyzedText] = useState('');
  const [importantInfo, setImportantInfo] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenTextract, setModalIsOpenTextract] = useState(false);
  const [modalIsOpenSearch, setModalIsOpenSearch] = useState(false);
  const [modalIsOpenImportant, setModalIsOpenImportant] = useState(false);
  const [queryParameters, setQueryParameters] = useState(['']);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadError, setUploadError] = useState('');

  const [modalIsOpenModel, setModalIsOpenModel] = useState(false);
  const [modelResults, setModelResults] = useState('');
  const [selectedModel, setSelectedModel] = useState('anthropic.claude-v2');
  const [maxTokens, setMaxTokens] = useState(1000);
  const [temperature, setTemperature] = useState(0);
  const [topP, setTopP] = useState(0);
  const [topK, setTopK] = useState(100);
  const [promptModelValue, setPromptModelValue] = useState("");
  const [outputFormat, setOutputFormat] = useState('json');
  const [responseTime, setResponseTime] = useState(0);
  const [textractTime, setTextractTime] = useState(0);

  const s3Client = new S3Client({
    region: 'us-east-1',
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
  });

  const textractClient = new TextractClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
  });

  const bedrockClient = new BedrockRuntimeClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
  });

  useEffect(() => {
    const listFiles = async () => {
      try {
        const command = new ListObjectsV2Command({ Bucket: 'pdfajson' });
        const response = await s3Client.send(command);
        const files = response.Contents
          .filter(file => ['.pdf', '.png', '.jpg', '.jpeg'].some(ext => file.Key.toLowerCase().endsWith(ext)))
          .map(file => ({
            key: file.Key,
            lastModified: file.LastModified,
            size: file.Size
          }));
        setFiles(files);
      } catch (error) {
        console.error('Error listing files:', error);
      }
    };

    listFiles();
  }, [s3Client]);

  const updateLoadingState = (fileKey, action, isLoading) => {
    setLoadingFiles(prevState => ({
      ...prevState,
      [fileKey]: {
        ...prevState[fileKey],
        [action]: isLoading
      }
    }));
  };

  const extractTextWithTextract = async (fileKey) => {
    try {
      const startCommand = new StartDocumentTextDetectionCommand({
        DocumentLocation: { S3Object: { Bucket: 'pdfajson', Name: fileKey } },
      });
      const startResponse = await textractClient.send(startCommand);
      const jobId = startResponse.JobId;

      let status = 'IN_PROGRESS';
      let getResponse;

      const maxAttempts = 20;
      let attempts = 0;
      const interval = 8000;

      while (status === 'IN_PROGRESS' && attempts < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, interval * Math.pow(2, attempts)));
        const getCommand = new GetDocumentTextDetectionCommand({ JobId: jobId });
        getResponse = await textractClient.send(getCommand);
        status = getResponse.JobStatus;
        attempts++;
      }

      if (status !== 'SUCCEEDED') {
        throw new Error('Text detection did not complete successfully');
      }

      const textBlocks = getResponse.Blocks.filter(block => block.BlockType === 'LINE');
      const extractedText = textBlocks.map(block => block.Text).join('\n');
      setExtractedText(extractedText);
      return extractedText;
    } catch (error) {
      console.error('Error extracting text:', error);
      setExtractedText('Error extracting text. Please try again.');
      throw error;
    }
  };

  const handleProcessFile = async (fileKey) => {
    updateLoadingState(fileKey, 'genAIJson', true);
    setSelectedFile(fileKey);
    try {
      const extractedText = await extractTextWithTextract(fileKey);
      const analyzeCommand = new InvokeModelCommand({
        body: JSON.stringify({
          prompt: `Human: Organiza el siguiente texto en un formato JSON usando solo la información extraída del PDF. No incluyas introducciones, explicaciones ni ningún otro texto que no esté en el pdf: ${extractedText}. Assistant:`,
          max_tokens_to_sample: 4000,
          temperature: 0.5,
          top_k: 250,
          top_p: 0.5,
          stop_sequences: []
        }),
        modelId: 'anthropic.claude-v2',
        contentType: 'application/json',
        accept: 'application/json'
      });

      const analyzeResponse = await bedrockClient.send(analyzeCommand);
      const responseJson = JSON.parse(new TextDecoder().decode(analyzeResponse.body));
      const analyzedTextJson = responseJson.completion.trim().replace(/\\n/g, '\n');
      setAnalyzedText(analyzedTextJson);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error extracting and analyzing text:', error);
      setExtractedText('Error extracting and analyzing text. Please try again.');
    } finally {
      updateLoadingState(fileKey, 'genAIJson', false);
    }
  };

  const handleExtractImportantInfo = async (fileKey) => {
    updateLoadingState(fileKey, 'genAIInfo', true);
    setSelectedFile(fileKey);
    try {
      const extractedText = await extractTextWithTextract(fileKey);
      const analyzeCommand = new InvokeModelCommand({
        body: JSON.stringify({
          prompt: `Human: Extrae la información más importante de los siguientes datos y organízala en un formato legible: ${extractedText}. Assistant:`,
          max_tokens_to_sample: 4000,
          temperature: 0.5,
          top_k: 250,
          top_p: 0.5,
          stop_sequences: []
        }),
        modelId: 'anthropic.claude-v2',
        contentType: 'application/json',
        accept: 'application/json'
      });

      const analyzeResponse = await bedrockClient.send(analyzeCommand);
      const responseJson = JSON.parse(new TextDecoder().decode(analyzeResponse.body));
      const importantInfoText = responseJson.completion.trim().replace(/\\n/g, '\n');
      setImportantInfo(importantInfoText);
      setModalIsOpenImportant(true);
    } catch (error) {
      console.error('Error extracting and analyzing important info:', error);
      setExtractedText('Error extracting and analyzing important info. Please try again.');
    } finally {
      updateLoadingState(fileKey, 'genAIInfo', false);
    }
  };

  const handleTextractFile = async (fileKey) => {
    updateLoadingState(fileKey, 'textract', true);
    setSelectedFile(fileKey);
    try {
      const extractedText = await extractTextWithTextract(fileKey);
      setExtractedText(extractedText);
      setModalIsOpenTextract(true);
    } catch (error) {
      console.error('Error extracting text:', error);
      setExtractedText('Error extracting text. Please try again.');
    } finally {
      updateLoadingState(fileKey, 'textract', false);
    }
  };

  const handleSearchFile = async () => {
    updateLoadingState(selectedFile, 'search', true);
    try {
      const extractedText = await extractTextWithTextract(selectedFile);
      const results = searchInText(extractedText, queryParameters);
      setSearchResults(results);
      setModalIsOpenSearch(true);
    } catch (error) {
      console.error('Error searching text:', error);
      setSearchResults({ error: 'Error searching text. Please try again.' });
      setModalIsOpenSearch(true);
    } finally {
      updateLoadingState(selectedFile, 'search', false);
    }
  };


  const handleModelFile = async () => {
    updateLoadingState(selectedFile, 'genAIModel', true);
    setModelResults("");
    setResponseTime(0);
    setTextractTime(0);

    const startTime = performance.now();
    const extractedText = await extractTextWithTextract(selectedFile);
    setTextractTime(calculateResponseTime(startTime, performance.now()));

  
    const maxTokensValue = parseInt(maxTokens, 10);
    const temperatureValue = parseFloat(temperature);
    const topKValue = parseInt(topK, 10);
    const topPValue = parseFloat(topP);
    let promptFinal = `Human: ${promptModelValue}. Extrae la información más importante de los siguientes datos y organízala en un formato legible: ${extractedText}. Assistant:`

    if (outputFormat === "json") {
      promptFinal = `Human: ${promptModelValue}. Organiza el siguiente texto en formato JSON. No agregues ningún texto adicional, introducciones o explicaciones que no estén en: ${extractedText}. Assistant:`;

    }
    //validar si promptModelValue es vacío
    const startTimeModel = performance.now();
    try {
      const analyzeCommand = new InvokeModelCommand({
        modelId: selectedModel,
        contentType: "application/json",
        accept: "application/json",
        body: JSON.stringify({
          anthropic_version: "bedrock-2023-05-31",
          max_tokens: maxTokensValue,
          temperature: temperatureValue,
          top_k: topKValue,
          top_p: topPValue,
          messages: [
            {
              role: "user",
              content: promptFinal
            }
          ]
        })
      });

      const analyzeResponse = await bedrockClient.send(analyzeCommand);
      const responseBody = new TextDecoder().decode(analyzeResponse.body);
      const responseJson = JSON.parse(responseBody);

      // Verificar si la respuesta contiene el campo content
      if (responseJson && responseJson.content && responseJson.content.length > 0) {
        const analyzedTextJson = responseJson.content[0].text.trim();
        setModelResults(analyzedTextJson);
      } else {
        console.error('La respuesta no contiene el campo esperado:', responseJson);
        setModelResults({ error: 'La respuesta no contiene información válida. Inténtalo de nuevo.' });
      }

      setResponseTime(calculateResponseTime(startTimeModel, performance.now()));

    } catch (error) {
      console.error('Error prompt text:', error);
      setModelResults({ error: 'Error searching text. Please try again.', error });
      setModalIsOpenModel(true);
    } finally {
      updateLoadingState(selectedFile, 'genAIModel', false);
    }
  }

  function calculateResponseTime(startTime, endTime) {
    const responseTimeInSeconds = ((endTime - startTime) / 1000).toFixed(2); // Calcula el tiempo en segundos
    return responseTimeInSeconds;
  }


  const searchInText = (text, queries) => {
    const results = {};
    queries.forEach(query => {
      const value = query;
      const regex = new RegExp(value, 'gi');
      const matches = [...text.matchAll(regex)].length;
      results[value] = matches;
    });

    const dateRegex = /\b\d{2}\/\d{2}\/\d{4}\b/g;
    const dates = [...text.matchAll(dateRegex)].map(match => new Date(match[0].split('/').reverse().join('-')));
    if (dates.length > 0) {
      results.min_date = new Date(Math.min(...dates)).toLocaleDateString('en-GB');
      results.max_date = new Date(Math.max(...dates)).toLocaleDateString('en-GB');
    }

    return {
      results
    };
  };

  const handleViewFile = (fileKey) => {
    const fileUrl = `https://pdfajson.s3.us-east-1.amazonaws.com/${fileKey}`;
    window.open(fileUrl, '_blank');
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setAnalyzedText('');
  };

  const closeModalTextract = () => {
    setModalIsOpenTextract(false);
    setExtractedText('');
  };

  const closeModalSearch = () => {
    setModalIsOpenSearch(false);
    setSearchResults(null);
  };

  const closeModalModel = () => {
    setModalIsOpenModel(false);
    setModelResults(null);
    setMaxTokens(1000);
    setTemperature(0);
    setTopP(0);
    setTopK(100);
    setPromptModelValue(null);
    setSelectedModel('anthropic.claude-v2');
    setOutputFormat('json');
    setModelResults(null);
    setResponseTime(0)
    setTextractTime(0);
  };

  const closeModalImportant = () => {
    setModalIsOpenImportant(false);
    setImportantInfo('');
  };

  const openSearchModal = (fileKey) => {
    setSelectedFile(fileKey);
    setModalIsOpenSearch(true);
  };

  const openModelModal = (fileKey) => {
    setSelectedFile(fileKey);
    setModalIsOpenModel(true);
  };

  const handlePromptModelChange = (e) => {
    setPromptModelValue(e.target.value);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleOutputFormatChange = (e) => {
    setOutputFormat(e.target.value);
  };

  const handleMaxTokensChange = (e) => {
    setMaxTokens(e.target.value);
  };

  const handleTemperatureChange = (e) => {
    setTemperature(e.target.value);
  };

  const handleTopPChange = (e) => {
    setTopP(e.target.value);
  };
  const handleTopKChange = (e) => {
    setTopK(e.target.value);
  };


  const addQueryParameter = () => {
    setQueryParameters([...queryParameters, '']);
  };

  const updateQueryParameter = (index, value) => {
    const newQueryParameters = [...queryParameters];
    newQueryParameters[index] = value;
    setQueryParameters(newQueryParameters);
  };

  const removeQueryParameter = (index) => {
    const newQueryParameters = queryParameters.filter((_, i) => i !== index);
    setQueryParameters(newQueryParameters);
  };

  const openUploadModal = () => {
    setUploadModalIsOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalIsOpen(false);
    setUploadFile(null);
    setUploadError('');
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = /(\.pdf|\.png|\.jpg|\.jpeg)$/i;
    if (file && !allowedExtensions.exec(file.name)) {
      setUploadError(t('file_not_allowed_select_pdf_png_jpg'));
      setUploadFile(null);
    } else {
      setUploadError('');
      setUploadFile(file);
    }
  };

  const handleUploadFile = async () => {
    if (!uploadFile) {
      alert(t('please_select_file_first'));
      return;
    }
    try {
      const params = {
        Bucket: 'pdfajson',
        Key: uploadFile.name,
        Body: uploadFile,
        ContentType: uploadFile.type
      };
      const command = new PutObjectCommand(params);
      await s3Client.send(command);
      alert(t('file_uploaded_successfully'));
      closeUploadModal();
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(t('error_alert'));
    }
  };

  return (
    <>
      <header className="bg-white shadow grid grid-cols-2 ">
        <div className="mx-10 max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
          <h1 className="text-1xl font-bold tracking-tight text-gray-900">
            {t('intelligent_document_processing')}
          </h1>
        </div>
        <div className="flex justify-end items-center mx-10">
          <Button onClick={openUploadModal} color="green" size="xs">
            <b>+</b> {t('upload_document')}
          </Button>
        </div>
      </header>
      <main className="px-12 mt-12 grid grid-cols-1 gap-1">
        <Card className="mt-5">
          <Title className="mb-5 max-w-lg">{t('select_file')}</Title>
          <Divider className="max-w-lg" />
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>{t('file_name')}</TableHeaderCell>
                <TableHeaderCell>{t('modification_date')}</TableHeaderCell>
                <TableHeaderCell>Bytes</TableHeaderCell>
                <TableHeaderCell>{t('process')}</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map(file => (
                <TableRow key={file.key}>
                 <TableCell>
  {file.key.length > 10
    ? `${file.key.slice(0, 40)}...${file.key.slice(file.key.lastIndexOf('.'))}`
    : file.key}
</TableCell>
                  <TableCell>{new Date(file.lastModified).toLocaleString()}</TableCell>
                  <TableCell>{file.size}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleProcessFile(file.key)}
                      disabled={loadingFiles[file.key]?.genAIJson}
                      size="xs"
                      className="mr-2"
                    >
                      {loadingFiles[file.key]?.genAIJson ? t('processing') : 'genAI json'}
                    </Button>
                    <Button
                      onClick={() => handleExtractImportantInfo(file.key)}
                      disabled={loadingFiles[file.key]?.genAIInfo}
                      size="xs"
                      className="mr-2"
                    >
                      {loadingFiles[file.key]?.genAIInfo ? t('processing') : 'genAI info'}
                    </Button>
                    <Button
                      onClick={() => handleTextractFile(file.key)}
                      disabled={loadingFiles[file.key]?.textract}
                      size="xs"
                      className="mr-2"
                    >
                      {loadingFiles[file.key]?.textract ? t('processing') : 'textract'}
                    </Button>
                    <Button
                      onClick={() => openSearchModal(file.key)}
                      disabled={loadingFiles[file.key]?.search}
                      size="xs"
                      className="mr-2"
                    >
                      {loadingFiles[file.key]?.search ? t('processing') : 'search'}
                    </Button>

                    <Button
                      onClick={() => openModelModal(file.key)}
                      disabled={loadingFiles[file.key]?.genAIModel}
                      size="xs"
                      className="mr-2"
                    >
                      {loadingFiles[file.key]?.genAIModel ? t('processing') : 'genAI model'}
                    </Button>

                    <Button
                      onClick={() => handleViewFile(file.key)}
                      icon={FaEye}
                      iconProps={{ size: 16 }}
                      variant="secondary"
                      className="mr-2"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel={t('analysis_results')}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80vh'
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{t('analysis_results')}</h2>
            <Button onClick={closeModal} variant="secondary" size="xs">
              <FaTimes />
            </Button>
          </div>
          <Textarea
            disabled={true}
            readOnly={true}
            value={analyzedText}
            className="text-black-900 mt-2"
            rows={20}
            placeholder={t("extracted_text_json_format")}
          />
        </Modal>
        <Modal
          isOpen={modalIsOpenImportant}
          onRequestClose={closeModalImportant}
          contentLabel={t('important_information')}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80vh'
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{t('important_information')}</h2>
            <Button onClick={closeModalImportant} variant="secondary" size="xs">
              <FaTimes />
            </Button>
          </div>
          <div className="text-black-900 mt-2" style={{ whiteSpace: 'pre-wrap' }}>
            {importantInfo}
          </div>
        </Modal>
        <Modal
          isOpen={modalIsOpenTextract}
          onRequestClose={closeModalTextract}
          contentLabel={t('extracted_text')}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80vh'
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{t('extracted_text')}</h2>
            <Button onClick={closeModalTextract} variant="secondary" size="xs">
              <FaTimes />
            </Button>
          </div>
          <Textarea
            disabled={true}
            readOnly={true}
            value={extractedText}
            className="text-black-900 mt-2"
            rows={20}
            placeholder={t('extracted_text')}
          />
        </Modal>
        <Modal
          isOpen={modalIsOpenSearch}
          onRequestClose={closeModalSearch}
          contentLabel={t('search_parameters')}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80vh'
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{t('search_parameters')}</h2>
            <Button onClick={closeModalSearch} variant="secondary" size="xs">
              <FaTimes />
            </Button>
          </div>
          {queryParameters.map((param, index) => (
            <div key={index} className="grid grid-cols-2 gap-2 mb-2 items-center">
              <TextInput
                value={param}
                onChange={(e) => updateQueryParameter(index, e.target.value)}
                placeholder="Query Parameter"
                className="mr-2"
              />
              <FaTimes
                onClick={() => removeQueryParameter(index)}
                style={{ cursor: 'pointer', color: 'red' }}
              />
            </div>
          ))}
          <Button onClick={addQueryParameter}>{t('add_parameter')}</Button>
          <Button onClick={handleSearchFile} disabled={loadingFiles[selectedFile]?.search}>
            {loadingFiles[selectedFile]?.search ? t('processing') : t('search')}
          </Button>
        </Modal>
        {searchResults && (
          <Modal
            isOpen={modalIsOpenSearch}
            onRequestClose={closeModalSearch}
            contentLabel={t("search_results")}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxHeight: '80vh'
              }
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2>{t("search_results")}</h2>
              <Button onClick={closeModalSearch} variant="secondary" size="xs">
                <FaTimes />
              </Button>
            </div>
            <Textarea
              disabled={true}
              readOnly={true}
              value={JSON.stringify(searchResults, null, 2)}
              className="text-black-900 mt-2"
              rows={20}
              placeholder={t("search_results")}
            />
          </Modal>
        )}      

        <Modal
          isOpen={modalIsOpenModel}
          onRequestClose={closeModalModel}
          contentLabel={t('search_modal')}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80vh'
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 className="text-1xl font-bold tracking-tight text-gray-900">
              {t('document')}: {selectedFile}
            </h1>
            <Button onClick={closeModalModel} variant="secondary" size="xs">
              <FaTimes />
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
              <label htmlFor="model-select">{t('model')}:</label>
              <select
                id="model-select"
                value={selectedModel}
                onChange={handleModelChange}
                className="mt-2 mb-4 w-full"
              >
                <option value="anthropic.claude-v2">Anthropic Claude-v2</option>
                <option value="anthropic.claude-3-haiku-20240307-v1:0">Anthropic Claude-3-haiku</option>
                <option value="anthropic.claude-3-5-sonnet-20240620-v1:0">Anthropic Claude-3-5-sonnet</option>
              </select>

            </div>
            <div style={{ flex: 1 }}>
              <label htmlFor="output-format">{t('output')}:</label>
              <select
                id="output-format"
                value={outputFormat}
                onChange={handleOutputFormatChange}
                className="mt-2 mb-4 w-full"
              >
                <option value="json">JSON</option>
                <option value="text">{t('text')}</option>
              </select>
            </div>
          </div>


          <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
              <label htmlFor="max-tokens">Max Tokens:</label>
              <input
                id="max-tokens"
                type="number"
                value={maxTokens}
                onChange={handleMaxTokensChange}
                className="mt-2 mb-4 w-full"
                min="1"
                max="4000"
              />
            </div>

            <div style={{ flex: 1 }}>
              <label htmlFor="temperature">Temperature:</label>
              <input
                id="temperature"
                type="number"
                value={temperature}
                onChange={handleTemperatureChange}
                className="mt-2 mb-4 w-full"
                min="0"
                max="1"
                step="0.01"
                onKeyDown={(e) => e.preventDefault()}
                onFocus={(e) => e.target.blur()}
              />
            </div>

            <div style={{ flex: 1 }}>
              <label htmlFor="topP">Top P:</label>
              <input
                id="top_p"
                type="number"
                value={topP}
                onChange={handleTopPChange}
                className="mt-2 mb-4 w-full"
                min="0"
                max="1"
                step="0.01"
                onKeyDown={(e) => e.preventDefault()}
                onFocus={(e) => e.target.blur()}
              />
            </div>

            <div style={{ flex: 1 }}>
              <label htmlFor="topK">Top K:</label>
              <input
                id="top_k"
                type="number"
                value={topK}
                onChange={handleTopKChange}
                className="mt-2 mb-4 w-full"
                min="0"
                max="500"
              />
            </div>
          </div>

          <label htmlFor="prompt-model">Prompt:</label>
          <Textarea
            value={promptModelValue}
            onChange={handlePromptModelChange}
            disabled={false}
            readOnly={false}
            className="text-black-400 mt-2 mb-4"
            rows={5}
            placeholder=""
          />

          <Button
            onClick={handleModelFile}
            disabled={loadingFiles[selectedFile]?.genAIModel}
          >
            {loadingFiles[selectedFile]?.genAIModel ? t('processing') : t('process')}
          </Button>



          <div style={{ marginBottom: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
              <label htmlFor="response-models">{t('response')}</label>

              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                  <label htmlFor="response-time" style={{ marginRight: '0.5rem' }}>{t('model_response_time')}:</label>
                  <input
                    id="response-time"
                    type="text"
                    value={responseTime}
                    readOnly
                    className="bg-gray-200 text-gray-700 cursor-default"
                    style={{ width: '50px', textAlign: 'right' }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="textract-time" style={{ marginRight: '0.5rem' }}>{t('textract_response_time')}:</label>
                  <input
                    id="textract-time"
                    type="text"
                    value={textractTime}
                    readOnly
                    className="bg-gray-200 text-gray-700 cursor-default"
                    style={{ width: '50px', textAlign: 'right' }}
                  />
                </div>
              </div>
            </div>

            <Textarea
              id="response-models"
              value={modelResults}
              disabled={true}
              readOnly={true}
              className="text-black-400"
              rows={20}
              placeholder=""
              style={{ width: '100%' }}
            />
          </div>








        </Modal>



        <Modal
          isOpen={uploadModalIsOpen}
          onRequestClose={closeUploadModal}
          contentLabel={t('upload_document')}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80vh'
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{t('upload_document')}: PDF, PNG, JPG, JPEG</h2>
            <Button onClick={closeUploadModal} variant="secondary" size="xs">
              <FaTimes />
            </Button>
          </div>
          <input type="file" onChange={handleFileChange} />
          {uploadError &&
            <div className="text-red-500 mt-2">{uploadError}</div>}
          <Button onClick={handleUploadFile} className="mt-4 ml-4"> {t('upload')}</Button>
        </Modal>
      </main>
    </>
  );
};

export default ProcesamientoInteligente;